import React, { useEffect, useState } from 'react';
import '../vendor_styles/cardoffer.scss'; // Include the necessary styles
import axios from 'axios';
import { toast } from 'react-toastify';

const OfferCard = ({ businessId }) => {
  const [offersData, setOffersData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [descriptionVisibility, setDescriptionVisibility] = useState({});

  useEffect(() => {
    if (!businessId) return;

    const fetchOffersByBusinessId = async () => {
      try {
        setLoading(true); // Start loading
        const response = await axios.post('https://vendor.payzopartner.in/payzo-api/getOffersbyBusinessId', {
          business_id: businessId,
        });

        if (response.data.success) {
          const offersWithRemainingTime = response.data.finalOffers.map(offer => ({
            ...offer,
            remainingTime: calculateRemainingTime(offer.end_date, offer.end_time),
          }));
          setOffersData(offersWithRemainingTime);
          // toast.success('Offers fetched successfully');
        } else {
          toast.error('Failed to fetch offers');
        }
      } catch (error) {
        console.error('Error fetching offers:', error);
        setError('Error fetching offers');
        toast.error('Error fetching offers');
      } finally {
        setLoading(false);
      }
    };

    fetchOffersByBusinessId();
  }, [businessId]);

  const calculateRemainingTime = (offerEndDate, offerEndTime) => {
    const [day, month, year] = offerEndDate.split("-");
    const [hours, minutes] = offerEndTime.split(":");
    const combinedEndDate = new Date(`${year}-${month}-${day}T${hours}:${minutes}:00`);
    const presentDate = new Date();
    const diffInMilliseconds = combinedEndDate - presentDate;

    if (diffInMilliseconds <= 0) return 'Offer Expired'; // Expired condition

    const days = Math.floor(diffInMilliseconds / (1000 * 60 * 60 * 24));
    const hoursLeft = Math.floor((diffInMilliseconds % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
    const minutesLeft = Math.floor((diffInMilliseconds % (1000 * 60 * 60)) / (1000 * 60));
    const secondsLeft = Math.floor((diffInMilliseconds % (1000 * 60)) / 1000);

    return `: ${hoursLeft} hours: ${minutesLeft} minutes: ${secondsLeft} seconds remaining`;
  };

  const updateRemainingTime = () => {
    setOffersData(prevOffers => {
      return prevOffers.map(offer => {
        const updatedRemainingTime = calculateRemainingTime(offer.end_date, offer.end_time);
        if (updatedRemainingTime === 'Offer Expired') {
          handleDeleteOffer(offer.id); 
        }
        return {
          ...offer,
          remainingTime: updatedRemainingTime, 
        };
      });
    });
  };

  const handleDeleteOffer = async (offerId) => {
    try {
      const response = await axios.delete('https://vendor.payzopartner.in/payzo-api/deleteOfferById', {
        data: { id: offerId },
      });
      if (response.data.success) {
        // toast.info('Offer deleted successfully');
        setOffersData(prevOffers => prevOffers.filter(offer => offer.id !== offerId));
      } else {
        toast.error('Failed to delete offer');
      }
    } catch (error) {
      console.error('Error deleting offer:', error);
      toast.error('Error deleting offer');
    }
  };

  const handleFullScreenDescription = (offer) => {
    // This function can open a modal or navigate to a full screen
    alert(`Full description for offer: ${offer.offer_title}\n\n${offer.offer_description}`);
  };

  useEffect(() => {
    const intervalId = setInterval(() => {
      updateRemainingTime();
    }, 1000);

    return () => clearInterval(intervalId);
  }, []);

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>{error}</div>;
  }

  if (!offersData || offersData.length === 0) {
    return <div>No offers available</div>;
  }

  return (
    <div className="offer-section-container">
      <h1 style={{ color: 'lightblue' }}>MY OFFERS</h1>
      <div className="offers-body-handle">
        {offersData.map((offer, index) => (
          <div className="offer-card-small" key={offer.id}>
            <div className="offer-card-imagehouse">
              <img
                className="offer-image"
                src={offer.offer_image || 'https://thumbs.dreamstime.com/b/special-offer-37086976.jpg'}
                alt="Offer"
              />
            </div>
            <h5>{offer.offer_title || 'Offer Name'}</h5>
            <p style={{color:"red", fontSize:"10px", fontWeight:"600"}}>offer Expires in: {offer.remainingTime}</p>
            <div className="offer-details">
              <p>Start Date: {offer.start_date || '12/10/2024'}</p>
              <p>End Date: {offer.end_date || '1/12/2025'}</p>
              <p>Start Time: {offer.start_time || '12:00 AM'}</p>
              <p>End Time: {offer.end_time || '1:00 AM'}</p>
              <p>Status: {offer.approval_status || 'Pending'}</p>
              {offer.remark && <p>Remark: {offer.remark}</p>}

              {descriptionVisibility[index] && offer.offer_description && (
                <div className="side-card">
                  <h4>Description:</h4>
                  <p>{offer.offer_description}</p>
                </div>
              )}

              <button
                onClick={() => handleDeleteOffer(offer.id)}
                style={{
                  width: '5rem',
                  height: '1.5rem',
                  border: 'none',
                  borderRadius: '1rem',
                  backgroundColor: 'red',
                  color: 'white',
                }}
              >
                Delete
              </button>

              {offer.offer_description && (
                <button
                  onClick={() => handleFullScreenDescription(offer)} // Call the function here
                  style={{
                    width: '7rem',
                    height: '1.5rem',
                    border: 'none',
                    borderRadius: '1rem',
                    backgroundColor: 'purple',
                    color: 'white',
                    marginTop: '10px',
                  }}
                >
                  Description
                </button>
              )}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default OfferCard;
