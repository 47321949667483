import React, {useState, useEffect} from 'react';
import "../vendor_styles/vendor_edit.scss";
import PayzoLogo from "../vendor_screens/vendor_images/vendorpayzo.png";
import { IoIosLogOut } from "react-icons/io";
import axios from 'axios';
import {toast, ToastContainer} from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import Offcanvass from '../off_canvas';

function EditBusinesss(){
    const [selectvalue, setSelectValue] = useState("");
  const [businessName, setBusinessName] = useState("");
  const [vendorName, setVendorName] = useState("");
  const [storetimings, setStoreTimings] = useState("");
  const [whatsappNumber, setWhatsappNumber] = useState("");
  const [Category, setCategory] = useState([]);
  const [storeCategory, setStoreCategory] = useState("");
  const [emailAddress, setEmailAddress] = useState("");
  const [businessAddress, setBusinessAddress] = useState("");
  const [areaPincode, setAreaPincode] = useState("");
  const [state, setState] = useState([]);
  const [selectedState, setSelectedState] = useState("");
  const [cities, setCities] = useState([]);
  const [selectedCity, setSelectedCity] = useState("");
  const [googleMapLink, setGoogleMapLink] = useState("");
  const [documentTitle, setDocumentTitle] = useState("");
  const [socialMediaLink, setSocialMediaLink] = useState("");
  const [kycDocument, setKycDocument] = useState("");
  const [businessImage, setBusinessImage] = useState("");
  const [kycImage, setKycImage] = useState("");
  const [promoCode, setPromoCode] = useState("");
  const [approvalStatus, SetApprovalStatus] = useState("updating");
  const [remark, setRemark] = useState("onhold");
  const [userIdHome, setUserIdHome] = useState([]);
  const navigate = useNavigate();
  const location = useLocation();
  const VendorId = location.state?.VendorId;
  const BusinessIdEditBusiness = location.state?.BusinessId;
    
       
  
  console.log(VendorId + "Vendor Id came in Edit Business")



  useEffect(() => {
    const fetchUpdateBusiness = async () => {
      try {
        const response = await axios.get(`https://vendor.payzopartner.in/payzo-api/getBusinessById/${BusinessIdEditBusiness}`);
        if (response.data.success === true) {
          setUserIdHome(response.data.business.vendor_id);
          const business = response.data.business; 
          setSelectValue(business.business_type);
          setBusinessName(business.business_name);
          setVendorName(business.vendor_name);
          setStoreTimings(business.store_timing);
          setWhatsappNumber(business.phone_number);
          setStoreCategory(business.category_id); 
          setEmailAddress(business.email);
          setBusinessAddress(business.address);
          setAreaPincode(business.pincode);
          setSelectedState(business.state_id);
          setSelectedCity(business.city_id);
          setGoogleMapLink(business.google_map_link);
          setDocumentTitle(business.documentTitle); 
          setBusinessImage(business.profile_image);
          setKycDocument(business.documentId);
          setGoogleMapLink(business.google_map_link)
        } else {
          console.log("No data found for the business.");
        }
      } catch (error) {
        console.error("Error fetching business data:", error);
      }
    };
    if (BusinessIdEditBusiness) {
      fetchUpdateBusiness();
    }
  }, [BusinessIdEditBusiness]); 
  


  const handleUpdateBusiness = async (e) => {
    e.preventDefault();
    try {
      const updateBusiness = await axios.post(
        "https://vendor.payzopartner.in/payzo-api/updateBusiness",
        {
          "business_id": BusinessIdEditBusiness, 
          "vendor_id": VendorId, 
          "promo_code": promoCode,
          "business_type": selectvalue,
          "business_name": businessName,
          "vendor_name": vendorName,
          "category_id": storeCategory,
          "store_timing": storetimings,
          "phone_number": whatsappNumber, 
          "state_id": selectedState,
          "city_id": selectedCity,
          "address": businessAddress,
          "email": emailAddress,
          "pincode": areaPincode,
          "google_map_link": googleMapLink,
          "website_link": socialMediaLink,
          "approval_status": approvalStatus,
          "remark": remark,
          "documentTitle": documentTitle,
          "profile_image": businessImage,
          "document_Image": kycImage,
        }
      ); 
      const { data } = updateBusiness;
      if (data.success) {
        toast.success("Business Updated Successfully");
        setTimeout(() => {
          navigate("/my-business", {
            state: {
              VendorId: VendorId,  // userId from wherever it is set
               // userIdHome should be available here
            }
          }); // Redirect using the UserIdHome
        }, 3000);
      } else {
        toast.error(`Business not Updated: ${data.message || "Unknown error"}`);
      }
    } catch (error) {
      // Enhanced error handling and user-friendly message
      console.error("Error updating business:", error);
      toast.error("An error occurred while updating the business. Please try again.");
    }
  };
  
  

  const handleImageChange = (e, setImage) => {
    if (e.target.files && e.target.files[0]) {
      setImage(e.target.files[0]);
    }
  };

  useEffect(() => {
    axios
      .get("https://vendor.payzopartner.in/payzo-api/getAllbusinesscategory")
      .then((res) => {
        console.log("category response" + res);
        if (Array.isArray(res.data.data)) {
          setCategory(res.data.data);
        } else {
          console.log("error occurred in category");
        }
      });
  }, []);


  useEffect(() => {
    axios
      .get("https://vendor.payzopartner.in/payzo-api/getStates")
      .then((res) => {
        console.log("API response for states:", res);
        if (Array.isArray(res.data.data)) {
          setState(res.data.data);
        } else {
          console.error("Expected an array but got:", res.data.data);
        }
      })
      .catch((error) => {
        console.error("There was an error fetching the states!", error);
      });
  }, []);

  useEffect(() => {
    if (selectedState) {
      axios
        .post("https://vendor.payzopartner.in/payzo-api/getCities", {
          state_id: selectedState
        })
        .then((res) => {
          console.log("API response for cities:", res);
          if (Array.isArray(res.data.data)) {
            setCities(res.data.data);
          } else {
            console.error("Expected an array but got:", res.data.data);
          }
        })
        .catch((error) => {
          console.error("There was an error fetching the cities!", error);
        });
    }
  }, [selectedState]);


  const handleLogut = (e)=> {
    e.preventDefault();
    window.localStorage.removeItem("PAYZOWEBSITEENCRYPTION");
    navigate("/screen-login")
  }
    return(
        // <div>
        //     <ToastContainer/>
        //   <div className='editbusiness-navbar-container'>
        //     <div className='editbusiness-navbar'>
        //         <img className='pay-logo' src={PayzoLogo} alt='' />
        //         <div style={{width:'15rem', height:'5rem', display:'flex', flexDirection:'row', justifyContent:'center', alignItems:'center', fontSize:'1.5rem', gap:'0.2rem', cursor:'pointer'}}>
        //         <p style={{
        //           width:"5rem",
        //           height:"2rem",
        //           backgroundColor:"lightblue",
        //           color:"white",
        //           borderRadius:"1rem",
        //           fontSize:"12px",
        //           display:"flex",
        //           justifyContent:"center",
        //           alignItems:"center",
        //           fontWeight : "600"
        //         }} onClick={handleLogut}>Logout</p>
        //         </div>
        //     </div>
        //     <div className='editbusiness-body'>
        //      <h1 style={{color:'lightblue'}}>Update Business</h1>
        //      <div className='updatebusiness-card'>
        //         <div className='revo'>
        //             <label>Business Type:</label>
        //             <select
        //           value={selectvalue}
        //           onChange={(e) => setSelectValue(e.target.value)}
                  
        //         >
        //           <option value="">Select Business Type</option>
        //           <option value="online">online</option>
        //           <option value="offline">offline</option>
        //         </select>
        //         </div>
        //         <div className='revo'>
        //             <label>Business Name</label>
        //             <input value={businessName} 
        //              className='revo-input'
        //               type='text'
        //                maxLength={30}
        //                placeholder='Enter Business Name'
        //                onChange={(e)=> setBusinessName(e.target.value)}
        //                required
        //                />
        //         </div>
        //         <div className='revo'>
        //             <label>Store Timings</label>
        //             <input 
        //              className='revo-input'
        //               type='text'
        //                placeholder=' 10:00AM to 10:00PM'
        //                value={storetimings}
        //                onChange={(e)=> setStoreTimings(e.target.value)}
        //                />
        //         </div>
        //         <div className='revo'>
        //             <label>Vendor Name</label>
        //             <input className='revo-input'
        //              type='text'
        //               placeholder='Enter Vendor Name' 
        //               value={vendorName}
        //               onChange={(e)=> setVendorName(e.target.value)}
        //               />
        //         </div>
        //         <div className='revo'>
        //             <label>Mobile Number</label>
        //             <input className='revo-input'
        //              type='phone'
        //               maxLength={10}
        //                placeholder='Enter Mobile Number'
        //                value={whatsappNumber}
        //                onChange={(e)=> setWhatsappNumber(e.target.value)}
        //                />
        //         </div>

        //         <div className='revo'>
        //             <label>Store Category</label>
        //             <select
        //             value={storeCategory}
        //             onChange={(e) => setStoreCategory(e.target.value)}
                  
        //           >
        //             <option value="">Select Category</option>
        //             {Category.map((item, index) => (
        //               <option key={index} value={item.id}>
        //                 {item.name}
        //               </option>
        //             ))}
        //           </select>
        //         </div>
        //         <div className='revo'>
        //             <label>Email Address</label>
        //             <input className='revo-input'
        //              type='text' 
        //              placeholder='Enter Email Address'
        //              value={emailAddress}
        //              onChange={(e)=> setEmailAddress(e.target.value)}
        //              />
        //         </div>
        //         <div className='revo'>
        //             <label>Business Address</label>
        //             <input className='revo-input'
        //              type='text'
        //               placeholder='Enter Business Address'
        //                value={businessAddress}
        //                onChange={(e)=> setBusinessAddress(e.target.value)}
        //               />
        //         </div>
        //         <div className='revo'>
        //             <label>Pincode</label>
        //             <input className='revo-input'
        //              type='phone'
        //               maxLength={6} 
        //               placeholder='Enter Pincode' 
        //                value={areaPincode}
        //                onChange={(e)=> setAreaPincode(e.target.value)}
        //               />
        //         </div>
        //         <div className='revo'>
        //             <label>Select State</label>
        //             <select
        //             value={selectedState}
        //             onChange={(e) => setSelectedState(e.target.value)}
                   
        //           >
        //             <option value="" disabled>
        //               Select a state
        //             </option>{" "}
        //             {state.map((item, index) => (
        //               <option key={index} value={item.id}>
        //                 {item.name}
        //               </option>
        //             ))}
        //           </select>
        //         </div>
        //         <div className='revo'>
        //             <label>Select city</label>
        //             <select
        //             value={selectedCity}
        //             onChange={(e) => setSelectedCity(e.target.value)}
                   
        //           >
        //             <option value="" disabled>
        //               Select a city
        //             </option>
        //             {cities.map((item, index) => (
        //               <option key={index} value={item.id}>
        //                 {item.name}
        //               </option>
        //             ))}
        //           </select>
        //         </div>
        //         <div className='revo'>
        //             <label>Google Map Link</label>
        //             <input className='revo-input'
        //              type='text' 
        //              placeholder='Google Map Link'
        //              value={googleMapLink}
        //              onChange={(e)=> setGoogleMapLink(e.target.value)}
                     
        //              />
        //         </div>
        //         <div className='revo'>
        //             <label>Website Link</label>
        //             <input className='revo-input'
        //              type='text'
        //               placeholder='Enter Website Link'
        //               value={socialMediaLink}
        //              onChange={(e)=> setSocialMediaLink(e.target.value)}
        //               />
        //         </div>
        //         <div className='revo'>
        //             <label>Kyc Document Title</label>
        //             <input className='revo-input'
        //              type='text'
        //               placeholder='Enter Kyc Document Name'
        //               value={documentTitle}
        //               onChange={(e)=> setDocumentTitle(e.target.value)}
        //               />
        //         </div>
        //         <div className='revo'>
        //             <label>Kyc Document  Id</label>
        //             <input className='revo-input' 
        //             type='text'
        //              placeholder='Enter Document Number' 
        //              value={kycDocument}
        //              onChange={(e)=> setKycDocument(e.target.value)}
        //              />
        //         </div>
        //         <div className='revo2'>
        //             <label>Upload Business Image</label>
        //             <input  type='file' 
        //             placeholder='Enter Business Image' 
        //             onChange={(e)=> handleImageChange(e, setBusinessImage)}
        //             />
        //         </div>
        //         <div className='revo2'>
        //             <label>Kyc Document  Image</label>
        //             <input  type='file'
        //              placeholder='Upload KyC Document Image'
        //              onChange={(e)=> handleImageChange(e, setKycImage)}
        //              />
        //         </div>
        //      </div>
        //      <div className='upload-button-container'>
        //         <button className='upload-btn' onClick={handleUpdateBusiness}>Update Business</button>
        //      </div>
        //     </div>

        //   </div>
        // </div>
        <div className='updatebusiness-main-container'>
          <ToastContainer/>
          <div className='updateBusiness-navbar'>
          <img className='pay-logo' src={PayzoLogo} alt='' />
                <div style={{width:'15rem', height:'5rem', display:'flex', flexDirection:'row', justifyContent:'center', alignItems:'center', fontSize:'1.5rem', gap:'0.2rem', cursor:'pointer'}}>
              <p id='lg' style={{
                  width:"5rem",
                  height:"2rem",
                  backgroundColor:"lightblue",
                  color:"white",
                  borderRadius:"1rem",
                  fontSize:"12px",
                  display:"flex",
                  justifyContent:"center",
                  alignItems:"center",
                  fontWeight : "600"
                }} onClick={handleLogut}>Logout</p>
                </div>
          </div>
          <div className='updateBusiness-body'>
           <div className='updateBusiness-header'>
             <h2 style={{color:"black", fontWeight:"700"}}>UPDATE BUSINESS</h2>
           </div>
           <div className='main-business-body'>
            <div className='remarker-selctor'>
                     <label>Business Type:</label>
                     <select
                     id='selctor-update'
                  value={selectvalue}
                  onChange={(e) => setSelectValue(e.target.value)}
                  
                >
                  <option value="">Select Business Type</option>
                  <option value="online">online</option>
                  <option value="offline">offline</option>
                </select>
                
            </div>
            <div className='remarker'>
            <strong>Enter Business Name</strong>
            <input value={businessName} 
                     className='update-input'
                      type='text'
                       maxLength={30}
                       placeholder='Enter Business Name'
                       onChange={(e)=> setBusinessName(e.target.value)}
                       required
                       />
            </div>
            <div className='remarker'>
                   <strong>Vendor Name</strong>
                  <input className='update-input'
                     type='text'
                      placeholder='Enter Vendor Name' 
                      value={vendorName}
                      onChange={(e)=> setVendorName(e.target.value)}
                      />
                </div>
            <div className='remarker'>
             <strong>Store Timings :</strong>
            <input 
              className='update-input'
              type='text'
              placeholder=' 10:00AM to 10:00PM'
              value={storetimings}
              onChange={(e)=> setStoreTimings(e.target.value)}
               />
            </div>
          
            <div className='remarker'>
                  <strong>Whatsapp Number</strong>
                  <input className='update-input'
                     type='phone'
                      maxLength={10}
                       placeholder='Enter Mobile Number'
                       value={whatsappNumber}
                       onChange={(e)=> setWhatsappNumber(e.target.value)}
                       />
                </div>

                <div className='remarker'>
                 <strong> Store Category</strong>
                   <select
                    value={storeCategory}
                    onChange={(e) => setStoreCategory(e.target.value)}
                  
                  >
                    <option value="">Select Category</option>
                    {Category.map((item, index) => (
                      <option key={index} value={item.id}>
                        {item.name}
                      </option>
                    ))}
                  </select>
                </div>
         
                <div className='remarker'>
                    <strong>Email Address</strong>
                  <input className='update-input'
                     type='text' 
                     placeholder='Enter Email Address'
                     value={emailAddress}
                     onChange={(e)=> setEmailAddress(e.target.value)}
                     />
                </div>

                <div className='remarker'>
                  <strong>Business Address</strong>
                   <input className='update-input'
                     type='text'
                      placeholder='Enter Business Address'
                       value={businessAddress}
                       onChange={(e)=> setBusinessAddress(e.target.value)}
                      />
                </div>

                <div className='remarker'>
                  <strong>Pincode</strong>
                  <input className='update-input'
                     type='phone'
                      maxLength={6} 
                      placeholder='Enter Pincode' 
                       value={areaPincode}
                       onChange={(e)=> setAreaPincode(e.target.value)}
                      />
                </div>

                <div className='remarker'>
                   <strong>Select State</strong>
                   <select
                    value={selectedState}
                    onChange={(e) => setSelectedState(e.target.value)}
                   
                  >
                    <option value="" disabled>
                      Select a state
                    </option>{" "}
                    {state.map((item, index) => (
                      <option key={index} value={item.id}>
                        {item.name}
                      </option>
                    ))}
                  </select>
                </div>

                <div className='remarker'>
                    <strong>Select City</strong>
                     <select
                    value={selectedCity}
                    onChange={(e) => setSelectedCity(e.target.value)}
                   
                  >
                    <option value="" disabled>
                      Select a city
                    </option>
                    {cities.map((item, index) => (
                      <option key={index} value={item.id}>
                        {item.name}
                      </option>
                    ))}
                  </select>
                </div>

                <div className='remarker'>
                  <strong>Google Map Link</strong>
                    <input className='update-input'
                     type='text' 
                     placeholder='Google Map Link'
                     value={googleMapLink}
                     onChange={(e)=> setGoogleMapLink(e.target.value)}
                     
                     />
                </div>

                <div className='remarker'>
                  <strong>Website Link</strong>
                    <input className='update-input'
                     type='text'
                      placeholder='Enter Website Link'
                      value={socialMediaLink}
                     onChange={(e)=> setSocialMediaLink(e.target.value)}
                      />
                </div>

                <div className='remarker'>
                  <strong>KYC Document Title</strong>
                 <input className='update-input'
                     type='text'
                      placeholder='Enter Kyc Document Name'
                      value={documentTitle}
                      onChange={(e)=> setDocumentTitle(e.target.value)}
                      />
                </div>

                <div className='remarker'>
                    <strong>KYC Document ID</strong>
                    <input className='update-input' 
                    type='text'
                     placeholder='Enter Document Number' 
                     value={kycDocument}
                     onChange={(e)=> setKycDocument(e.target.value)}
                     />
                </div>
                <div className='remarker-uploader'>
                    <strong>Upload New Business Image </strong>
                    <input  type='file' 
                    placeholder='Enter Business Image' 
                    onChange={(e)=> handleImageChange(e, setBusinessImage)}
                    />
                </div>
                <div className='remarker-uploader'>
                    <strong>KYC Document Image</strong>
                    <input  type='file'
                     placeholder='Upload KyC Document Image'
                     onChange={(e)=> handleImageChange(e, setKycImage)}
                     />
                </div>

                <div className='remarkers'>
                <button className='upload-btns' onClick={handleUpdateBusiness}>Update Business</button>
              </div>
             

           </div>
          </div>

        </div>
    )
}

export default EditBusinesss;