import React from "react";
import "../../styles/forgotpass.scss";
import axios from "axios";
import { toast } from "react-toastify";

function ForgotPasswordVendor(){


    const handleForgotPasswordSubmit = async(e)=> {
        e.preventDefault();
        try {
            const forgotresponse = await axios.post('https://vendor.payzopartner.in/payzo-api/forgotPassword-vendor', {
                'userId' : "",
                 'cPassword' : "",
                 'nPassword' : ""
            })
            if(forgotresponse.data.success === true){
                toast.success("Password Updated Successfully")
            }else{
                toast.error("Failed to update  Password")
            }
        } catch (error) {
            console.log(error);
        }
    }


    return (
        <div>
          <div className="forgotpassword-main-container">
           <div className="forgotpassword-card">
              <h2>Forgot Password</h2>
              <input className="pass-inp" type="text" placeholder="Please enter Old Password" />
              <input  className="pass-inp" type="text" placeholder="Please enter New Password" />
              <button className="forgot-submit">Submit</button>
           </div>
          </div>
        </div>
    )
}
export default ForgotPasswordVendor;