import { React } from "react";
import "../styles/services.scss";
import { useNavigate } from "react-router-dom";
import Logo3 from "../old_images/logo.png";
import { useState } from "react";
import { Carousel } from "antd";

function Services() {
  const navigate = useNavigate();

  return (
    <div>
      <div className="service-container-main">
        <div>
          <img className="logo-3" src={Logo3} alt="" />
        </div>
        <div className="service-navs">
          <p
            className="ps"
            style={{ cursor: "pointer" }}
            onClick={() => navigate("/")}
          >
            Home
          </p>
          <p
            className="ps"
            style={{ cursor: "pointer" }}
            onClick={() => navigate("/services")}
          >
            {" "}
            Services
          </p>
          <p
            className="ps"
            style={{ cursor: "pointer" }}
            onClick={() => navigate("/blog-card")}
          >
            Blogs
          </p>
          <p
            className="ps"
            style={{ cursor: "pointer" }}
            onClick={() => navigate("/feedback")}
          >
            Feedback
          </p>
        </div>
      </div>
      <div className="case-control">
        <Carousel className="car-internal" autoplay>
          <div className="peter">
            <h1>Payzo</h1>
            <p className="peter-son">
              Payzo is a robust business development platform meticulously
              designed to bridge the crucial gap between enterprises and their
              valued customers. Catering primarily to organizations aspiring to
              cultivate authentic leads and enhance customer engagement, Payzo
              leverages the power of online advertising, focusing on both online
              and offline sales channels. With a suite of valuable features
              including Payzo Ads, Offline offers, Offer cards, Business stores,
              Golden hour deals, Payzo ATM, as well as the facilitation of
              business networks and conclaves, it offers an integrated approach
              to bolster the visibility and footfall for businesses, resulting
              in increased revenue and promising opportunities. It mutually
              benefits both the business and customers.
            </p>
          </div>
          <div className="peter">
            <h1>Payzo Ads</h1>
            <p>
              It's a cutting-edge online advertising feature developed by
              PayzoPartner. Advertisers can seamlessly present concise yet
              impactful ads, service offerings, and product listings to smart
              phones and web users. With versatile Ad placement options, where
              one can place ads on influencer walls in app and website. Payzo
              Ads offers a choice of pricing models, including Pay-Per-Click
              (PPC), Pay Per Like (PPL), and Pay Per Feedback (PPF), providing a
              lucrative opportunity for influencers and users alike.
            </p>
          </div>
          <div className="peter">
            <h1>Ads</h1>
            <p>
              Ads are all about that the advertiser posting their advertisement
              on their profile by which the user can access & engage. Whether
              you're a small business owner or an individual looking to sell
              products or services, with our platform, you can effortlessly post
              ads, generate leads, and boost engagement. You can reach the
              targeted audience and generate genuine leads.
            </p>
          </div>
          <div className="peter">
            <h1>Door Knock Ads</h1>
            <p>
              Door knock ads are given to customers/user in the separate
              section, where they can avail coupons, gifts, discounts and many
              more. The Door Knock Ads feature efficiently displays
              advertiser-created ads, transforming them into engaging scratch
              cards for users' enjoyment.
            </p>
          </div>
          <div className="peter">
            <h1>Online offers</h1>
            <p>
              Experience the power of Payzo's online offers feature for your
              business. Advertise with us and effortlessly showcase your
              exclusive online deals to a vast audience of potential customers.
              Elevate your brand by presenting your enticing offers to a broad
              customer base. Our platform is your gateway to ensuring that your
              promotions reach the right audience, maximizing your ROI. Plus, we
              make it easy for users to access and enjoy your exclusive deals.
              At Payzo, we're all about connecting your business with the
              perfect customers to make your offers truly shine.
            </p>
          </div>
          <div className="peter">
            <h1>Business Network</h1>
            <p>
              Introducing the Payzo Business Network, a vital feature within our
              app that's poised to reform the way professionals and
              entrepreneurs connect and communicate in today's fast-paced
              business landscape. We understand that effective networking is
              paramount for achieving success, and that's precisely why we've
              designed the Payzo Business Network to empower you. With our
              cutting-edge platform, you can effortlessly establish connections
              and streamline information exchange using a single broadcast
              message. Our powerful broadcast feature ensures precision and
              efficiency in your networking endeavors, making business
              communication a breeze. At Payzo, we're dedicated to simplifying
              your path to success.
            </p>
          </div>
          <div className="peter">
            <h1>Conclaves</h1>
            <p>
              "Introducing 'Conclaves' on Payzo, a feature designed to empower
              you in organizing and sharing your business meetups and gatherings
              with utmost ease. We're here to make accessibility, networking,
              and knowledge exchange a breeze, creating valuable opportunities
              for you and your diverse audience. With 'Conclaves,' connecting
              and thriving in your business community has never been simpler."
            </p>
          </div>
          <div className="peter">
            <h1>Payzo ATM</h1>
            <p>
              This feature offers a convenient and efficient solution for cash
              withdrawals at local business stores, benefiting both customers
              and business owners. It offers for all customers a seamless &
              hassle free experience while withdrawing money. Simply open the
              app, locate the nearest participating shop, and Head over to the
              designated counter at the shop, scan unique withdrawal code, and
              receive your cash hassle-free. It's a convenient and efficient
              solution for those times when you need cash on the go.
            </p>
          </div>
          <div className="peter">
            <h1>Offline Offers</h1>
            <p>
              Payzo enables businesses to distribute offline offers to attract
              customers. These offers can be in the form of coupons & discounts
              which customers can redeem at the business's physical location.
              This feature encourages foot traffic and enhances customer
              engagement.
            </p>
          </div>
          <div className="peter">
            <h1>Golden Hour Deals</h1>
            <p>
              Payzo offers businesses the option to run "Golden Hour Deals,"
              which are time-limited promotions during specific hours of the
              day. This feature encouraging customers to take advantage of the
              exclusive discounts or offers within the designated time frame.
              Payzo will notify users whenever any store, including shopping
              malls, restaurants, or groceries, offers products or services as
              part of the golden hour deals.
            </p>
          </div>
          <div className="peter">
            <h1>Business Stores</h1>
            <p>
              Our app makes it effortless for users to discover various business
              stores located throughout India. Whether you're looking for a
              well-known retail chain, a local boutique, a restaurant, or any
              other commercial establishment, our comprehensive database offer
              current information such as store addresses, contact details,
              opening hours, and user reviews. This enables users to make
              informed decisions and easily locate nearby stores. Payzo provides
              a convenient list of business stores listing out their offers &
              discounts, for customers to access.
            </p>
          </div>
        </Carousel>
      </div>
    </div>
  );
}

export default Services;
