import React from "react";
import "../styles/terms.scss";
import Termtable from "./term_table";

function TermsandConditions() {
  return (
    <div className="term-main">
      <div className="Term-container">
        <div className="center-text">
          <h2>Terms and Conditions</h2>
          <p>
            Customer understands, acknowledges and agrees that these Terms and
            Conditions (“Terms”), are made as a part of the [Payzo], which
            governs the access and use of the Service (as defined below)
            provided by BISSI YAAZI TECHNOLOGIES PRIVATE LIMITED (“Company”), an
            Indian company with a principal place of business located at D No
            9-312, Ravi Nagar, Naidu Thota, Pendurthi Visakhapatnam AP India
            530047.
          </p>
          <p>
            By opening an Account or otherwise using the Service, the Customer
            affirms its consent to these Terms. If a Customer is acting on
            behalf of or for the benefit of a corporate entity or other third
            party, Customer represents and warrants that it has the necessary
            authority to agree to these Terms and to bind such corporate entity
            or third party to these Terms.
          </p>
        </div>
        <div className="definition">
          <h6>About our Services</h6>
          <ol>
            <li>
              Privacy And Security Principles: Since we started Payzo, we've
              built our Services with strong privacy and security principles in
              mind.
            </li>
            <li>
              Ways To Improve Our Services: To enhance our services and support
              businesses on Payzo, we conduct an analysis of your usage
              patterns. This analysis aids in measuring the efficiency and reach
              of the services and messages offered by businesses. Payzo
              collaborates with partners, service providers and affiliated
              companies to gather and utilize this information for these
              purposes. Your data is utilized responsibly and in line with our
              commitment to improving the overall user experience and business
              outcomes on Payzo.
            </li>
            <li>
              Company continuously is working on adding improving the features
              of the Services and may do so in its discretion and as part of
              such, Company may also remove certain function which are not
              frequently used or are replaced by other functions. There is no
              guarantee by the Company to maintain certain features and Customer
              acknowledges that current features available in the Platform are
              subject to change by the Company in its discretion.
            </li>
            <li>
              Communicating With Businesses: We are committed to enhancing
              communication between you, businesses and organizations through
              our Services. This includes facilitating notifications,
              product/service updates, and marketing initiatives to improve your
              overall experience.
            </li>
            <li>
              Safety, Security And Integrity: Our utmost commitment is to
              safeguard the safety, security and overall integrity of our
              Services. This encompasses our dedication to effectively address
              and mitigate instances of abusive behaviour and activities that
              contravene our Terms and policies. We are resolute in preventing
              the misuse of our Services, which includes any harmful conduct
              towards others. In situations where we identify such activities or
              individuals, we will take the necessary and appropriate actions,
              which may involve the removal of offending parties or activities,
              as well as potential engagement with law enforcement authorities.
              Our approach to removal aligns with the guidelines outlined in the
              "Termination" section detailed below.
            </li>
            <li>
              Enabling Access To Our Services: In order to deliver our Services
              seamlessly across India, it is imperative that we store and
              distribute content and information through data centers and
              systems located nationwide, which may extend beyond your state of
              residence. This strategic utilization of a nationwide
              infrastructure is indispensable for the efficient provision of our
              Services. It's important to note that this infrastructure may be
              under the ownership or operation of our trusted service providers,
              including affiliated companies. Please be aware that our app
              operates exclusively within the geographical boundaries of India.
            </li>
            <li>
              User Registration: Users are required to register for the service
              using accurate information, including their current mobile phone
              number.Users may also receive text messages and phone calls with
              registration codes.
            </li>
            <li>
              Age: Users must be at least 12 years old to register and use the
              service, or meet the age requirements specified in their country
              or territory to register without parental approval. If users are
              below the required age, they have no access to the services we
              provide.
            </li>
            <li>
              Devices And Software: Users are responsible for providing the
              necessary devices, software and data connections to use the
              service. They must also agree to download and install updates as
              required. Notifications from the service may be sent as necessary.
            </li>
            <li>
              Fees And Taxes: Users are responsible for any fees associated with
              their use of the service, including carrier data plans, internet
              fees and other taxes.
            </li>
          </ol>
        </div>
        {/* Table start here */}

        <Termtable />

        <div>
          <h4>Third-Party Services</h4>
          <ol>
            <li>
              "Our Services may provide access to, enable interaction with, or
              support integration with third-party websites, applications,
              content, products, and services, as well as products offered by
              other companies ('Other Company Products'). For instance, you
              might opt to use third-party data backup services (e.g., iCloud or
              Google Drive) that are integrated with our Services or engage with
              a 'share' feature on a third-party website, which facilitates
              sharing information with your Payzo contacts.
            </li>
            <li>
              It is important to understand that these Terms and our Privacy
              Policy are specifically applicable to your usage of our Services.
              When you choose to use third-party products or services, please be
              aware that their respective terms and privacy policies will govern
              your interactions with those products or services."
            </li>
            <li>
              "As our website, platform and services may contain links to
              third-party websites that are not operated by our company ('Third
              Party Websites'), we wish to make it clear that our company
              assumes no responsibility or liability for the content,
              information, or any use of these Third Party Websites. Our company
              shall not, under any circumstances, be held accountable for any
              damages of any nature arising from or in connection with the use
              of Third Party Services and/or Third Party Websites, or reliance
              on the information presented therein. Users are advised that such
              usage is at their own discretion, risk, and responsibility."
            </li>
          </ol>
        </div>
        <div className="reg">
          <h6>Intellectual Property</h6>
          <ol>
            <li>
              Customer acknowledges and agrees that all proprietary and/or
              Intellectual Property Rights in and to the Service and/or to the
              Platform or anything related thereto, and all documentation,
              including without limitation all inventions, patent, patent
              application, copyright, registered design, trademark, trade dress
              or other industrial property right in respect thereto, as well as
              any Confidential Information, belong to the Company alone. This
              Agreement does not transfer ownership of the Platform or
              underlying technology or any part thereof.
            </li>
            <li>
              Any Intellectual Property Rights in and to any content made by the
              Customer or on its behalf shall be the sole property of the
              Customer and its assignees. However, any feedback, advises or
              comments received by the Company from the Customer or any other
              user, which relates to the Platform and/or the Service shall be
              deemed to be property of the Company and if required, shall be
              fully assigned to the Company without consideration.
            </li>
            <li>
              It is clarified that the Customer acknowledges that any
              Intellectual Property Rights belonging to the PAYZO, whether by
              virtue of law or the terms and conditions applicable
            </li>
          </ol>
        </div>
        <div className="cond">
          <h5>Licenses </h5>
          <p>
            PAYZO: We own all copyrights, trademarks, domains, logos, trade
            dress, trade secrets, patents, and other intellectual property
            rights associated with our Services. You may not use our copyrights,
            trademarks (or any similar marks), domains, logos, trade dress,
            trade secrets, patents, and other intellectual property rights
            unless you have our express permission and except in accordance with
            our Brand Guidelines. You may use the trademarks of our affiliated
            companies only with their permission, including as authorized in any
            published brand guidelines.
          </p>
          <p>
            Your License ToPayzo: In order to operate and provide our Services,
            you grant Payzo a worldwide, non-exclusive, royalty-free, sub
            licensable and transferable license to use, reproduce, distribute,
            create derivative works of, display and perform the information
            (including the content) that you upload, submit, store, send, or
            receive on or through our Services. The rights you grant in this
            license are for the limited purpose of operating and providing our
            Services (such as to allow us to display your profile picture and
            status message, transmit your messages, and store your undelivered
            messages on our servers for up to 30 days as we try to deliver
            them).
          </p>
          <p>
            PAYZO’s License To You: We grant you a limited, revocable,
            non-exclusive, non- sub licensable and non-transferable license to
            use our Services, subject to and in accordance with our Terms. This
            license is for the sole purpose of enabling you to use our Services
            in the manner permitted by our Terms. No licenses or rights are
            granted to you by implication or otherwise, except for the licenses
            and rights expressly granted to you.
          </p>
          <h6>Dispute Resolution</h6>
          <p>
            Forum And Venue: If you are a Payzo user located in the India , you
            agree that any claim or cause of action you have against PAYZO
            relating to, arising out of, or in any way in connection with our
            Terms or our Services and for any claim or cause of action that
            Payzo files against you, you and Payzo agree that any such claim or
            cause of action (each, a “Dispute,” and together, “Disputes”) will
            be resolved exclusively in the India Court for the VISAKHAPATNAM ,
            ANDHRAPRADESH and you agree to submit to the personal jurisdiction
            of such courts for the purpose of litigating any such claim or cause
            of action, and the laws of the VISAKHAPATNAM , ANDHRAPRADESH will
            govern any such claim or cause of action without regard to conflict
            of law provisions. Without prejudice to the foregoing, you agree
            that, in our sole discretion, we may elect to resolve any Dispute we
            have with you that is not subject to arbitration in any competent
            court in the country in which you reside that has jurisdiction over
            the Dispute.
          </p>
          <p>
            Governing Law: Our Terms and any potential Disputes between Payzo
            and you, whether they are addressed in court or through arbitration,
            are subject to the laws of India, disregarding any conflict of law
            principles.
          </p>
          <p>
            Time Limit To Bring A Claim Or Dispute: THESE TERMS ALSO LIMIT THE
            TIME YOU HAVE TO BRING A CLAIM OR DISPUTE, INCLUDING THE TIME TO
            START ARBITRATION OR, IF PERMISSIBLE, A COURT ACTION OR SMALL CLAIMS
            PROCEEDING TO THE FULLEST EXTENT PERMITTED BY APPLICABLE LAW.
          </p>
          <p>
            Both parties, namely "we" and "you," acknowledge and agree that in
            the case of any Dispute any Claims, including the initiation of
            arbitration proceedings, must be initiated within one year from the
            date when the Dispute initially arose. Failure to do so will result
            in the permanent barring of such Dispute. In practical terms, this
            means that if either party, "we" or "you," does not bring forth a
            Claim, including commencing arbitration proceedings, within one year
            from the date the Dispute first arose, the arbitration process will
            be dismissed on the grounds that it was initiated beyond the
            allowable timeframe.
          </p>
        </div>
        <div className="termination">
          <h5>Termination of our Services</h5>
          <li>
            Company may close an Ad Account for any reason. In addition, Company
            may suspend or deny access to the Service (and the Platform) or use
            thereof upon the occurrence of any of the following, with immediate
            effect: (i) if a Customer or an Additional user assigned thereto
            commits a material breach of any of its undertakings, warranties and
            representations hereunder, and such breach is not remedied within
            ten (10) days following written notice with respect to the breach;
            upon (ii) if a Customer breaches the terms and conditions relating
            to an Ad Network or the Company is requested by an Ad Network in
            writing to refrain from providing services to the Customer. Such
            termination shall be without prejudice to any other right.
          </li>
          <li>
            Upon termination and/or expiration of the Term for any reason, the
            Customer and any Additional Users appointed by it or any of their
            clients shall no longer be allowed to use or access the Service (or
            the Platform).
          </li>
          <li>
            We reserve the right, but are not obligated to, review the content
            you upload to the Service, to make sure that it complies with these
            Terms. If we, in our discretion, determine that such content
            violates these Terms, we may remove it from the Service, with or
            without notice to you; and may discontinue, terminate or suspend you
            User Account, or your use of the Service, temporarily or
            permanently. If we terminate your right to use the Service, you will
            NOT GET ANY REFUND.
          </li>
          <li>
            Upon cancellation or termination by client for any reason: (i) we
            will cease providing you services and you will no longer be able to
            access your account; (ii) you will not be entitled to any refunds,
            and you shall pay us all unpaid amounts owing.
          </li>
        </div>
        <div className="obj">
          <h5>Limitation of Liabilty</h5>
          <li>
            • Under no circumstances shall the Company or its affiliates be held
            liable for indirect, incidental, consequential, special, or
            exemplary damages, including, but not limited to, losses in revenue,
            anticipated profits, or damage to business or reputation, even if
            the Company's representatives have been advised of the possibility
            of such damages or should have reasonably foreseen them. In cases
            where, despite the terms outlined herein, the Company is found
            liable for damages of any kind, including negligence, related to or
            arising from these terms, the total liability of the Company shall
            not exceed the fee paid to the Company by the Customer. Both parties
            acknowledge that this limitation represents a fair distribution of
            risk, taking into consideration the cost of the Service, and that
            the Company would not have provided the service except under the
            terms specified herein.
          </li>
        </div>
        <div className="int-property">
          <h5>Disclaimers and Release</h5>
          <li>
            YOU USE OUR SERVICES AT YOUR OWN RISK AND SUBJECT TO THE FOLLOWING
            DISCLAIMERS. WE ARE PROVIDING OUR SERVICES ON AN “AS IS” BASIS
            WITHOUT ANY EXPRESS OR IMPLIED WARRANTIES, INCLUDING, BUT NOT
            LIMITED TO, WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR
            PURPOSE, TITLE, NON-INFRINGEMENT, AND FREEDOM FROM COMPUTER VIRUS OR
            OTHER HARMFUL CODE. WE DO NOT WARRANT THAT ANY INFORMATION PROVIDED
            BY US IS ACCURATE, COMPLETE, OR USEFUL, THAT OUR SERVICES WILL BE
            OPERATIONAL, ERROR FREE, SECURE, OR SAFE, OR
          </li>
          <li>
            THAT OUR SERVICES WILL FUNCTION WITHOUT DISRUPTIONS, DELAYS, OR
            IMPERFECTIONS. WE DO NOT CONTROL, AND ARE NOT RESPONSIBLE FOR,
            CONTROLLING HOW OR WHEN OUR USERS USE OUR SERVICES OR THE FEATURES,
            SERVICES, AND INTERFACES OUR SERVICES PROVIDE. WE ARE NOT
            RESPONSIBLE FOR AND ARE NOT OBLIGATED TO CONTROL THE ACTIONS OR
            INFORMATION (INCLUDING CONTENT) OF OUR USERS OR OTHER THIRD-PARTIES.
            YOU RELEASE US, OUR SUBSIDIARIES, AFFILIATES, AND OUR AND THEIR
            DIRECTORS, OFFICERS, EMPLOYEES, PARTNERS, AND AGENTS (TOGETHER, THE
            “PAYZOPARTNER PARTIES”) FROM ANY CLAIM, COMPLAINT, CAUSE OF ACTION,
            CONTROVERSY, DISPUTE, OR DAMAGES (TOGETHER, “CLAIM”), KNOWN AND
            UNKNOWN, RELATING TO, ARISING OUT OF, OR IN ANY WAY CONNECTED WITH
            ANY SUCH CLAIM YOU HAVE AGAINST ANY THIRD-PARTIES. YOUR RIGHTS WITH
            RESPECT TO THE PAYZO PARTIES ARE NOT MODIFIED BY THE FOREGOING
            DISCLAIMER IF THE LAWS OF YOUR COUNTRY OR TERRITORY OF RESIDENCE,
            APPLICABLE AS A RESULT OF YOUR USE OF OUR SERVICES, DO NOT PERMIT
            IT.
          </li>
          <li>
            The subscription money is non-refundable for Subscribers who are
            availing the subscription through app for the life time access.
          </li>
          <li>
            All charges for purchases are non-refundable, and there are no
            refunds or credits for partially used periods.
          </li>
          <li>
            You must be at least 12 years of age to create an account Payzo
          </li>
          <li>
            In payzo wallet, for withdrawal the minimum amount should be
            Rs.100/- and transfer charges should be charged for transaction as
            per bank norms.
          </li>
          <li>
            In Offer zone, company is not liable to the offers provided by the
            vendor or stores, all the offers information in the offer zone is
            related to that particular stores only and those stores liable to
            that offer information.
          </li>
          <li>
            Vendors or Store owners who are posting (offering) offers in Offer
            zone, has a privilege to update offers only from 10:00PM to 8:00AM
            till the valid time period.
          </li>
          <li>
            Offers in offer zone is valid up to that offered stock availability
            only.
          </li>
          <li>
            In Business stores, If the information provided on stores seems to
            be different from original, then company is not responsible for such
            incidents. That whole information responsibility is on the
            respective business store owners only.
          </li>
          <li>
            Payzo is not responsible for the conduct of any member on or off of
            the Service.
          </li>
          <li>
            User need to agree to use caution in all interactions with other
            members, particularly if he/she decide to communicate off the
            Service or meet in person.
          </li>
          <li>
            In addition, User need to agree that he/she will not provide their
            financial information (for example, your credit card or bank account
            information), or wire or otherwise send money to other members.
          </li>
          <li>
            Users are solely responsible for their interactions with other
            members.
          </li>
          <li>
            User need to agree that all information that he/she submit upon
            creation of your account, is accurate and truthful.
          </li>
          <li>
            Payzomay investigate and take any available legal action in response
            to illegal and / or unauthorized uses of the Service, including
            termination of your account.
          </li>
          <li>
            If any legal issues are raised from both sides (company or customer
            & vendor) or one of the sides, then should be dealt in the
            Visakhapatnam magistrate only.
          </li>
          <li>
            If information provided by the vendor or business store is wrong or
            find to be wrong, the store activities are removed/banned from
            company and legal actions should be taken against that particular
            vendor/ store.
          </li>
          <li>
            We reserve the right to designate in the future that certain of our
            Services are governed by separate terms (where, as applicable, you
            may separately consent).
          </li>
          <li>
            Our Services are not intended for distribution to or use in any
            country or territory where such distribution or use would violate
            local law or would subject us to any regulations in another country
            or territory. We reserve the right to limit our Services in any
            country or territory.
          </li>
          <li>
            Our Terms are written in English any translated version is provided
            solely for your convenience. To the extent any translated version of
            our Terms conflicts with the English version, the English version
            controls. Any amendment to or waiver proposed by you of our Terms
            requires our express consent.
          </li>
          <li>
            We may amend or update these Terms. We will provide you notice of
            material amendments to our Terms, as appropriate, and update the
            "Last modified" date at the top of our Terms. Your continued use of
            our Services confirms your acceptance of our Terms, as amended. We
            hope you will continue using our Services, but if you do not agree
            to our Terms, as amended, you must stop using our Services by
            deleting your account.
          </li>
          <li>
            All of our rights and obligations under our Terms are freely
            assignable by us to any of our affiliates or in connection with a
            merger, acquisition, restructuring, or sale of assets, or by
            operation of law or otherwise, and we may transfer your information
            to any of our affiliates, successor entities, or new owner. In the
            event of such an assignment, these Terms will continue to govern
            your relationship with such third-party. We hope you will continue
            using our Services, but if you do not agree to such an assignment,
            you must stop using our Services by deleting your account after
            having been notified of the assignment.
          </li>
          <li>
            You will not transfer any of your rights or obligations under our
            Terms to anyone else without our prior written consent.
          </li>
          <li>
            Except as contemplated herein, our Terms do not give any third-party
            beneficiary rights.
          </li>
          <li>
            If we fail to enforce any of our Terms, it will not be considered a
            waiver.
          </li>
          <li>
            If any provision of these Terms is found to be unlawful, void, or
            for any reason unenforceable, then that provision shall be deemed
            amended to the minimum extent necessary to make it enforceable, and
            if it cannot be made enforceable then it shall be deemed severable
            from our Terms and shall not affect the validity and enforceability
            of the remaining provisions of our Terms, and the remaining portion
            of our Terms will remain in full force and effect except as set
            forth "
          </li>
          <li>
            We reserve all rights not expressly granted by us to you. In certain
            jurisdictions, you may have legal rights as a consumer, and our
            Terms are not intended to limit such consumer legal rights that may
            not be waived by contract.
          </li>
          <li>
            We always appreciate your feedback or other suggestions about Payzo
            and our Services, but you understand that you have no obligation to
            provide feedback or suggestions and that we may use your feedback or
            suggestions without any restriction or obligation to compensate you
            for them.
          </li>
          <p>*Last updated Terms & Conditions on 24th August 2024.</p>
        </div>
      </div>
    </div>
  );
}

export default TermsandConditions;
