import React, { useEffect, useState } from "react";
import "../vendor_styles/vendor_smallcard.scss";
import PayzLogo from "../vendor_screens/vendor_images/vendorpayzo.png";
import Offcanvass from "../off_canvas";
import { useNavigate, useLocation } from "react-router-dom";
import axios from "axios";
import { AiOutlineFullscreen } from "react-icons/ai";
import { toast } from 'react-toastify';

function VendorShowBusiness() {
  const navigate = useNavigate();
  const location = useLocation();
  const VendorId = location.state?.VendorId;
  const userIdRollBack = location.state?.VendorId;
  const [data, setData] = useState([]);





  // Fetch business data for the user
  useEffect(() => {
    const fetchBusinessData = async (VendorId) => {
      try {
         const response = await axios.get(`https://vendor.payzopartner.in/payzo-api/getBusinessesByUserId/${VendorId}`, {
          vendor_id : VendorId
         })
        if (response.data.success) {
          const businesses = response.data.businesses || [];
          setData(businesses);
        } else {
          toast.error('No businesses found for this user.');
        }
      } catch (error) {
        console.error("Error fetching businesses", error);
      }
    };

    if (VendorId || userIdRollBack) {
      fetchBusinessData(VendorId || userIdRollBack);
    }
  }, [VendorId, userIdRollBack]);

  // Handle logout
  const handleLogut = (e) => {
    e.preventDefault();
    window.localStorage.removeItem("PAYZOWEBSITEENCRYPTION");
    navigate("/screen-login");
  };

  return (
    <div>
      <div className="vendor-small-container">
        <div className="vendor-small-navbar">
          <p><Offcanvass /></p>
          <img className="center-logo-business" src={PayzLogo} alt="Payz Logo" />
          <button style={{width:'5rem', height:'2rem', }} className="logout-button" onClick={handleLogut}>Logout</button>
        </div>

        <div className="smallcard-body-container">
          <h1 className="bark" style={{ color: 'lightblue', fontWeight: '700' }}>Your Active Businesses</h1>
          {data.length === 0 ? (
            <p>No businesses found for this user.</p>
          ) : (
            data.filter(item => ['pending', 'rejected', 'updating', 'approved'].includes(item.approval_status))
                .map((item) => (
                  <div key={item.business_id} className="business-smallcard">
                    <img 
                      className="business-smallcard-image" 
                      src={item.profile_image} 
                      alt={item.business_name} 
                      style={{
                        width: '90%', 
                        aspectRatio: '1 / 1', 
                        objectFit: 'contain'
                      }} 
                    />
                    <div className="business-card-footer">
                      <p style={{ fontWeight: '700' }}>{item.business_name}</p>
                      <div className="zone">
                        <p style={{ color: 'violet' }}>{item.business_type} store</p>
                        <p
                          onClick={() =>
                            navigate("/business-overview", {
                              state: { VendorId, businessId: item.business_id }
                            })
                          }
                          style={{ color: 'purple', fontSize: '1.5rem' }}
                        >
                          <AiOutlineFullscreen />
                        </p>
                      </div>
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', gap: '1rem' }}>
                      <p style={{ fontWeight: '700' }}>Approval Status: -</p>
                      <p
                        style={{
                          color: item.approval_status === 'pending' ? 'orange' :
                                 item.approval_status === 'approved' ? 'green' :
                                 item.approval_status === 'rejected' ? 'red' :
                                 'black',  
                          fontWeight: '700'
                        }}
                      >
                        {item.approval_status}
                      </p>
                    </div>
                  </div>
                ))
          )}
        </div>
      </div>
    </div>
  );
}

export default VendorShowBusiness;
