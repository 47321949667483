import React, { useState, useEffect, useCallback } from "react";
import "../vendor_styles/vendor_bigscreen.scss";
import PayzLogo from "../vendor_screens/vendor_images/vendorpayzo.png";
import { useNavigate, useLocation } from "react-router-dom";
import axios from "axios";
import { toast, ToastContainer } from "react-toastify";
import { FaStar } from "react-icons/fa";
import Offcanvass from "../off_canvas";
import { FaCircleInfo } from "react-icons/fa6";
import { ClipLoader } from 'react-spinners';
import OfferCard from "./offercard";

function VendorBigScreen() {
  const navigate = useNavigate();
  const location = useLocation();
  const [store, setStore] = useState(null);
  const [rating, setRating] = useState(0);
  const [offers, setOffers] = useState([]);
  const [loading, setLoading] = useState(true); // Loading state
  const VendorId = location.state?.VendorId;
  const BusinessId = location.state?.businessId;


  console.log(VendorId  + "Big Screen lo vachinda")

  // Fetch Store Data
  const fetchStore = useCallback(async () => {
    if (!BusinessId) {
      toast.error("Business ID is missing. Please try again.");
      return;
    }
    try {
      setLoading(true);  // Start loading
      const response = await axios.get(
        `https://vendor.payzopartner.in/payzo-api/getBusinessById/${BusinessId}`
      );
      if (response.data.success && response.data.business) {
        setStore(response.data.business);
        setRating(response.data.business.averageRating || 0);
      } else {
        toast.error("Failed to retrieve business data.");
      }
    } catch (error) {
      toast.error("Failed to fetch business data. Please check your network or try again later.");
    } finally {
      setLoading(false);  // End loading
    }
  }, [BusinessId]);

  

  useEffect(() => {
    fetchStore();
    // fetchOffersByBusiness();
  }, [fetchStore]);

  // Handle Create Offer Button Click
  const handleCreateOffer = () => {
    navigate("/offer-select", {
      state: { businesstype: store?.business_type, VendorId, BusinessId, }
    });
  };

  // Render Stars for Rating
  const renderStars = (rating) => {
    return [...Array(5)].map((_, i) => (
      <FaStar key={i} style={{ color: i < rating ? "yellow" : "grey" }} />
    ));
  };

  // Handle Logout
  const handleLogut = (e) => {
    e.preventDefault();
    window.localStorage.removeItem("PAYZOWEBSITEENCRYPTION");
    navigate("/screen-login");
  }

  // Handle Information Button Click
  const handleInformation = () => {
    if (store?.remark) {
      alert("Remarks: " + store.remark);
    } else {
      toast.info("No remarks available.");
    }
  };

  const handleDeleteBusiness = async (business_id) => {
    const userConfirmed = window.confirm("Are you sure you want to delete this business?");
    
    if (!userConfirmed) return; 
    
    try {
      const response = await axios.delete(
        'https://vendor.payzopartner.in/payzo-api/deleteBusiness',
        { data: { business_id } }
      );
      
      if (response.data.success) {
        toast.success("Business Deleted Successfully");
        
        setTimeout(() => {
          navigate("/my-business", {
            state: { VendorId } // Passing userId as state when navigating
          });
        }, 2000);
        
      } else {
        toast.error("Failed to Delete Business");
      }
      
    } catch (error) {
      toast.error("Please try again later.");
      console.error('Error deleting business:', error);  // Improved error logging
    }
  };
  
  // Dynamic Styling for Approval Status
  const getApprovalStatusColor = (status) => {
    switch (status) {
      case 'approved':
        return 'green';
      case 'pending':
        return 'orange';
      case 'rejected':
        return 'red';
      default:
        return 'black'; 
    }
  };

  return (
    <div>
      <ToastContainer />
      <div className="bigscreen-main-container">
        <div className="bigscreen-navbar">
          <div className="bigscreen-toggler">
            <p className="off-canva">

            <Offcanvass  />
            </p>
            <img className="bigscreen-main-logo" src={PayzLogo} alt="Payz Logo" />
            <button className="bigscreen-out" onClick={handleLogut} aria-label="logout">Logout</button>
          </div>
        </div>

        <div className="bigscreen-heading">
          <h1 style={{ color: "black" }}>Business Overview</h1>
        </div>

        <div className="bigscreen-body-container">
          {loading ? (
            <ClipLoader size={50} color="lightblue" />
          ) : (
            <div className="bigcard-distrub">
              <div className="ras">
                <img className="bigcard-center-image" src={store.profile_image} alt={store.business_name} style={{
                  width: '90%',
                  aspectRatio: '1 / 1',
                  objectFit: 'contain',
                  border: '3px dotted lightgray'
                }} />
                <div className="starrating-container" style={{ backgroundColor: 'lightgreen' }}>
                  {renderStars(rating)}
                </div>
                <h4 style={{color:"purple"}}>Business Name: {store.business_name}</h4>
                <p style={{color:'purple'}}>Business Type: {store.business_type}</p>
                <h5 style={{color:'purple'}}>Business Category: {store.category_name}</h5>
              </div>

              <div className="address-container">
                <h1 style={{ color: 'Purple' }}>Business Address</h1>
                <div style={{ display: 'flex', flexDirection: 'row', gap: '1rem' }}>
                  <strong style={{color:'purple'}}>Address:</strong>
                  <p>{store.address}</p>
                </div>
                <div style={{ display: 'flex', flexDirection: 'row', gap: '1rem' }}>
                  <strong style={{color:'purple'}}>Pincode:</strong>
                  <p>{store.pincode}</p>
                </div>
                <div style={{ display: 'flex', flexDirection: 'row', gap: '1rem' , justifyContent:"center", alignContent:"center"}}>
                  <strong style={{marginTop:'0.5rem', color:"purple"}}> Approval Status:</strong>
                  <p style={{ color: getApprovalStatusColor(store.approval_status), marginTop:'0.5rem', marginRight:'3rem', fontWeight:"600" }}>
                    {store.approval_status.toUpperCase()}
                  </p>
                  {store.approval_status === 'rejected' && (
                     <button
                     onClick={handleInformation}
                     style={{
                       width: '1.5rem',
                       height: '1.5rem',
                       borderRadius: '50%',
                       display: 'flex',
                       justifyContent: 'center',
                       alignItems: 'center',
                       color: 'brown',
                       border: 'none',
                      
                     }}
                   >
                     <FaCircleInfo />
                   </button>
                  )}
                 
                </div>
                <div style={{ display: 'flex', flexDirection: 'row', gap: '1rem' }}>
                  <strong style={{color:'purple'}}>Mobile:</strong>
                  <p>{store.phone_number}</p>
                </div>
                <div style={{ display: 'flex', flexDirection: 'row', gap: '1rem' }}>
                  <strong style={{color:'purple'}}>Email:</strong>
                  <p>{store.email}</p>
                </div>
                <div style={{ display: 'flex', flexDirection: 'row', gap: '1rem' }}>
                  <strong style={{color:'purple'}}>City Name:</strong>
                  <p>{store.city_name}</p>
                </div>
                <div style={{ display: 'flex', flexDirection: 'row', gap: '1rem' }}>
                  <strong style={{color:'purple'}}>State Name:</strong>
                  <p>{store.state_name}</p>
                </div>
                <div className="buttons-remarker">
                  {store.approval_status === "approved" && (
                    <button className="bigscreen-btns" onClick={handleCreateOffer}>Create Offer</button>
                  )}
                  <button className="bigscreen-btns" onClick={() => navigate('/edit-business', { state: { VendorId, BusinessId } })}>Edit Business</button>
                  <button
                    className="bigscreen-btns"
                    onClick={() => handleDeleteBusiness(store.business_id)}>
                    Delete Business
                  </button>
                  
                </div>
              </div>
            </div>
          )}
        </div>

        <div style={{ width: '100%', height: '15rem', display: 'flex', justifyContent: 'center', alignItems: 'center', fontSize: '2.5rem', color: 'lightblue', fontWeight: '700' }}>
          <p>
            <marquee style={{
              color : "black"
            }}>Only Offline Offers are Avaliable....</marquee>
          </p>
        </div>

        {store && store.business_id && <OfferCard businessId={store.business_id} />}
      </div>
    </div>
  );
}

export default VendorBigScreen;
