import React from "react";
import "../styles/cancellation.scss";
import Footer from "../footer";

function Cancellation() {
  return (
    <div className="main-cancel">
      <div className="cancel-container">
        <h3>Cancellation and Refund Policy</h3>
        <p>
          As a service-based company, we do not offer any physical or tangible
          products for sale. Our core business revolves around providing
          services. If you have any questions or require further information
          about our services or policies, please feel free to contact our
          customer support team for assistance.
        </p>
        <h5>Subscription Policy</h5>
        <p>Refundable Charges:</p>
        <ol>
          <li>
            Only if a subscription was purchased but not added due to a
            technical glitch then refund will be considered.
          </li>
          <li>
            Refunds will be initiated within 24 to 48 hours and credited to the
            respective accounts.
          </li>
        </ol>

        <div>
          <h5>Non-Refundable Charges:</h5>
          <ol>
            <li>
              General Policy: All charges for subscriptions with Payzo are
              non-refundable. Once a subscription fee is paid, it cannot be
              refunded, regardless of whether the subscription is used partially
              or in full.
            </li>
            <li>
              Partial Usage: No refunds or credits will be issued for any unused
              portion of the subscription period. This policy applies to both
              ongoing and canceled subscriptions.
            </li>
          </ol>
        </div>
        <div>
          <h5>Cancellation Policy:</h5>
          <ol>
            <li>
              Cancellation Request: Subscribers may cancel their subscription at
              any time by contacting our support team at hello@payzopartner.com.
            </li>
            <li>
              • No Refunds for Cancellations: Cancellations will not entitle the
              subscriber to a refund or credit for any unused time remaining in
              the subscription period.
            </li>
          </ol>
        </div>
        <div>
          <h5>Contact Information:</h5>
          <ol>
            <li>
              For questions or concerns regarding our subscription policy,
              please reach out to us at hello@payzopartner.com.
            </li>
          </ol>
        </div>
        <div>
          <h5>Return and Shipping Policies</h5>
          <h4>Return Policy:</h4>
          <p>
            At this time, BissiYaazi Technologies Private Limited (Payzopartner)
            does not offer a return policy for our purchases. As our core
            business revolves around providing services.
          </p>
        </div>
        <div>
          <h4>Shipping Policy</h4>
          <p>
            BissiYaazi Technologies Private Limited (Payzopartner) currently
            does not offer shipping services. As our core business revolves
            around providing services.
          </p>
        </div>
        <div>
          <h5>Contact Information</h5>
          <p>
            For any questions or further assistance, please reach out to us at:
          </p>
          <ol>
            <li>Email: hello@payzopartner.com</li>
            <li>Phone: +91 9898070769</li>
          </ol>
          <p>Last updated Cancellation Policy on 24th August 2024.</p>
        </div>
      </div>
    </div>
  );
}

export default Cancellation;
