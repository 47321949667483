import React, { useState, useEffect } from 'react';
import "../styles/screenone.scss";
import PayzoLogo from "../../src/components/vendor_screens/vendor_images/vendorpayzo.png";
import { GrLinkNext } from "react-icons/gr";
import axios from 'axios';
import { toast, ToastContainer } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import { Spinner } from "react-bootstrap";

function ScreenOne() {
  const navigate = useNavigate();
  const [phoneNumber, setPhoneNumber] = useState('');
  const [loading, setLoading] = useState(false);
  
  
  
  useEffect(()=>{
  
      const handlePopState = () => {
    // Force a redirect to prevent going back to the profile page
    navigate("/", { replace: true });
  };
  //     // Continuously push the current state every time the user navigates away
  const intervalId = 
    window.history.pushState(null,null, '/');
   
  
  // Cleanup the event listener and interval on component unmount
  return () => {
    clearInterval(intervalId);
    window.removeEventListener("popstate", handlePopState);
  };
  })
  // Loading initially set to false

  // Handle phone number input change
  const handleMobileNumberChange = (e) => {
    const value = e.target.value;
    if (/^\d{0,10}$/.test(value)) {
      setPhoneNumber(value); // Only allows digits, up to 10
    }
  };

  // Handle form submission
  const handleMobileNumberConfirm = async (e) => {
    e.preventDefault();

    if (!phoneNumber) {
      toast.error("Please enter a phone number");
      return;
    }

    setLoading(true);

    try {
      const response = await axios.post('https://vendor.payzopartner.in/payzo-api/verifyPhone', {
        'phone_number': phoneNumber,
        'status': "no"
      });

      const phoneId = response.data.result[0].phone_id;
      const PhoneNumber = response.data.result[0].phone_number;

      if (response.data.success === true) {
        toast.success("New Number Registered Successfully");
      }

      setTimeout(() => {
        if (response.data.result[0].status === "no") {
          navigate('/screen-signup', { state: { phoneId, PhoneNumber } });
        } else if (response.data.result[0].status === "yes") {
          navigate("/screen-login");
        }
      }, 500);
    } catch (error) {
      if (error.response && error.response.data && error.response.data.message) {
        toast.error(error.response.data.message);
      } 
    } finally {
      setLoading(false); // Hide the loading spinner after request completes
    }
  };

  return (
    <div>
      <ToastContainer />
      <div className='screenmain-container'>
        {loading ? (
          <div className="d-flex justify-content-center align-items-center" style={{ height: "100vh" }}>
            <Spinner variant="primary" animation="border" role="status" />
            <span style={{ marginLeft: "1rem", color:"white", fontSize:"1.5rem" }} className="sr-only">Youre Redirected to....</span>
          </div>
        ) : (
          <div className='screenmain-card'>
            <img className='pay-logo' src={PayzoLogo} alt='Payzo Logo' />
            <h6>Mobile Number Validation</h6>
            <input
              value={phoneNumber}
              onChange={handleMobileNumberChange}
              maxLength={10}
              className='screenmain-input'
              type='text'
              placeholder='Enter Mobile Number'
              disabled={loading} // Disable input when loading
            />
            <div className='alberto'>
              <button className='nxt-btn' onClick={handleMobileNumberConfirm} disabled={loading}>
                <GrLinkNext />
              </button>
              <h5>Next</h5>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default ScreenOne;
