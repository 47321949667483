import React from 'react';
import "../src/styles/deleteaccount.scss";
import DeleteAccountImage from "../src/images/deleteaccount_new.jpg"
function DeleteAccount(){
    return(
        <div>
             <div className='delete-account-container'>
                 <div className='delete-card'>
                   <img src={DeleteAccountImage} alt='' className='image-makeup' />
                 </div>
             </div>
        </div>
    )
}

export default DeleteAccount;