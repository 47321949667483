
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Blogcard from "./components/blog";
import Services from "./components/services";
import FeedBack from "./components/feedback";
import TermsandConditions from "./components/terms";
import PrivacyPolicy from "./components/privacy";
import Cancellation from "./components/cancellation";
// import VendorSignup from "./components/vendor_screens/vendor_signup";
// import VendorLogin from "./components/vendor_screens/vendor_login";
import VendorProfile from "./components/vendor_screens/vendor_profile";
import VendorChangePassword from "./components/vendor_screens/vendor_changePassword";
import VendorShowBusiness from "./components/vendor_screens/vendor_showBusiness";
import VendorBigScreen from "./components/vendor_screens/vendor_Bigscreen";
// import ForgotPassword from "./components/vendor_screens/vendor_forgotPassword";
import VendorOfferSelect from "./components/vendor_screens/vendor_offerSelect";
import VendorUpdateBusiness from "./components/vendor_screens/vendor_updateBusiness";
import ScreenOne from "./components/screenone";
import DeleteAccount from "./deleteaccount";
import NewDashboard from "./newdashboard";
import { useEffect } from "react";
import EditBusinesss from "./components/vendor_screens/vendor_editBusiness";
import ScreenLogin from "./components/screenlogin";
import Offercards from "./components/offercard";
import PrivateRoute from "./components/privateroute";
import ScreenSignup from "./components/screensignup";
import ForgotPasswordVendor from "./components/vendor_screens/forgotpasswordscreen";



function App() {

  return (
    <div>
      <BrowserRouter>
        <Routes> 


          {/* publicRotes */}
          <Route path="/" element={<NewDashboard/>} />
          <Route path="/services" element={<Services />} />
          <Route path="/blog-card" element={<Blogcard />} />
          <Route path="/feedback" element={<FeedBack />} />
          <Route path="/terms-conditions" element={<TermsandConditions />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route path="/cancellation-policy" element={<Cancellation />} />
          {/* <Route path="/vendor-signup" element={<VendorSignup />} />
          <Route path="/vendor-login" element={<VendorLogin />} /> */}
          {/* <Route path="/forgot-password" element={<ForgotPassword />} />  */}
          <Route path="/screen-one" element={<ScreenOne/>}/>
          <Route path="/screen-login" element={<ScreenLogin/>} />
           <Route path="/screen-signup" element={<ScreenSignup/>} />
          <Route path="/forgot-password-vendor" element={<ForgotPasswordVendor/>} />
          <Route path="/delete-account" element={<DeleteAccount/>}/>
         {/* privateRoutes */}
         <Route element={<PrivateRoute/>}>
         <Route path="/vendor-profile" element={<VendorProfile />} />
         <Route path="/change-password" element={<VendorChangePassword />} />
          <Route path="/my-business" element={<VendorShowBusiness />} />
          <Route path="/business-overview" element={<VendorBigScreen />} />
          <Route path="/offer-select" element={<VendorOfferSelect />} />
          <Route path="/update-Business" element={<VendorUpdateBusiness />} />
          <Route path="/edit-business" element={<EditBusinesss/>} />
         <Route path="/offers" element={<Offercards/>} />
         </Route>
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
