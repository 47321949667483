import React from "react";
import "../styles/privacy.scss";
import { useTable } from "react-table";
import Tables from "./table";

function PrivacyPolicy() {
  return (
    <div className="privacy-origin">
      <div className="privacy-container">
        <div className="pr">
          <h2>PRIVACY POLICY</h2>
          <h4>Legal Info & Our Privacy Policy explains :</h4>
          <p>
            • We respect your privacy and are committed to protecting it through
            our compliance with this privacy policy (“Policy”). This Policy
            describes the types of information we may collect from you or that
            you may provide “Personal Information” in the “Payzo” mobile
            applications “Mobile Application” or “Service”.
          </p>
          <p>• How we maintain your privacy of your information and our data</p>
          <p>
            • We, BISSI YAAZI TECHNOLOGIES PRIVATE LIMITED ("Payzo ," "our,"
            "we," or "us") product developers, marketing and protects data and
            use the information for our research and development purpose of
            other app’s / services under, BISSI YAAZI TECHNOLOGIES PRIVATE
            LIMITED. • Payzois built with a set of strong privacy principles in
            mind. • This policy does not apply to the practices of organizations
            that we do not own or to people that we do not employ or
            manage.Personal Information will be kept confidential and will be
            used for our research, marketing, and strategic client analysis
            objectives and other internal business purposes only. We do not sell
            or rent Personal Information except that in case you are a customer
            of our search services through any of the Media, your Personal
            Information shall be shared with our subscribers/advertisers and you
            shall be deemed to have given consent to the same. • User / Service
            Provider orProduct / Service of any particular subscriber /
            advertiser.
          </p>
          <p>
            We will share Personal Information only under one or more of the
            following circumstances: - If we have your consent or deemed consent
            to do so -
          </p>
          <p>
            • In furtherance of the confidentiality with which we treat Personal
            Information we have put in place appropriate physical, electronic
            and managerial procedures to safeguard and secure the information
            wecollect online. We give you the ability to edit your account
            information and preferences at any time, including whether you want
            us to contact you about new services. To protect your privacy and
            security, we will also take reasonable steps to verify your identity
            before granting access or making corrections
          </p>
        </div>
        <div className="inf-we">
          <h5>Information we Collect</h5>
          <p>
            Payzogather personal information to operate, provide, improve,
            understand, customize, support, and market our/your skills Services,
            Payzotry to gather the required info when you install, access, or
            use our Services.The types of information we receive and collect
            depend on how you use our Services. We require certain information
            to deliver our Services and without this we will not be able to
            provide our services to you. For example, you must provide your
            mobile phone number to create an account to use our Services. Our
            Services have optional features which, if used by you, require us to
            collect additional information to provide such features. You will be
            notified of such collection, as appropriate. If you choose not to
            provide the information needed to use a feature, you will be unable
            to use the feature. For example, you cannot share your location with
            your contacts if you do not permit us to collect your location data
            from your device. Permissions can be managed through your Settings
            menu on Web, Android and iOS devices.
          </p>
        </div>
        <div>
          <Tables />
        </div>
        <div className="maintain">
          <h4>Information You Provide:</h4>
          <ul>
            <li>
              Your Account Information:You must provide your mobile phone number
              and basic information (including a profile name of your choice) to
              create a Payzoaccount. If you don’t provide us with this
              information, you will not be able to create an account to use our
              Services. You can add other information to your account, such as a
              profile picture and "about" information.
            </li>
            <li>
              Your Connections:You can use the contact upload feature and
              provide us, if permitted by applicable laws, with the phone
              numbers in your address book on a regular basis, including those
              of users of our Services and your other contacts. If any of your
              contacts aren’t yet using our Services, we’ll manage this
              information for you in a way that ensures those contacts cannot be
              identified by us. You can provide a group profile picture or
              description.
            </li>
            <li>
              Customer Support and Other Communications:When you contact us for
              customer support or otherwise communicate with us, you may provide
              us with information related to your use of our Services, including
              copies of your messages, any other information you deem helpful
              and how to contact you (e.g., an email address). For example, you
              may send us an email with information relating to app performance
              or other issues.
            </li>
            <li>
              As you are sharing data with us as service provider, you are
              agreeing to provide your contact details to share with user (
              who’s looking for your services ) Information we share: Since this
              application primary objective is to promote, share & find nearest
              service provider as individual, it is necessary to share data such
              as: (mobile number, distance to the user(location services),
              abilities, intro message and reviews from the earlier users.) user
              can directly contact using (call, message and WhatsApp) contact
              method and service provider who could help the user to fix the
              issue/ query.
            </li>
            <li>
              Usage and Log Information:We collect information about your
              activity on our Services, like service-related, diagnostic and
              performance information. This includes information about your
              activity (including how you use our Services, your Services
              settings, how you interact with others using our Services
              (including when you interact with a business), and the time,
              frequency, and duration of your activities and interactions), log
              files and diagnostic, crash, website, and performance logs and
              reports. This also includes information about when you registered
              to use our Services; the features you use like our messaging,
              calling and status of the profile building.
            </li>
            <li>
              Location Information:We collect and use precise location
              information from your device with your permission when you choose
              to use location-related features, like when you decide to share
              your location with your contacts or view locations nearby or
              locations others have shared with you. There are certain settings
              relating to location-related information which you can find in
              your device settings or the in-app settings, such as location
              sharing. Even if you do not use our location-related features, we
              use IP addresses and other information like phone number area
              codes to estimate your general location (e.g., city and country).
              We also use your location information for diagnostics and
              troubleshooting purposes.
            </li>
            <li>
              User Reports:Just as you can report other users, other users or
              third parties may also choose to report to us your interactions
              and your messages with them or others on our Services; for
              example, to report possible violations of our Terms or policies.
              When a report is made, we collect information on both the
              reporting user and reported user. To find out more about what
              happens when a user report is made, please see Advanced Safety and
              Security Features.
            </li>
            <li>
              Third-Party Service Providers:We work with third-party service
              providers and other to help us operate, provide, improve,
              understand, customize, support, and market our Services. For
              example, we work with them to distribute our apps; provide our
              technical and physical infrastructure, delivery, and other
              systems; provide engineering support, cybersecurity support, and
              operational support; supply location, map, and places information;
              process payments; help us understand how people use our Services;
              market our Services; help you connect with businesses using our
              Services; conduct surveys and research for us; ensure safety,
              security, and integrity; and help with customer service. These
              companies may provide us with information about you in certain
              circumstances; for example, app stores may provide us with reports
              to help us diagnose and fix service issues.
            </li>
            <h5>Disclosure of Information</h5>
            <p>
              Depending on the requested Services or as necessary to complete
              any transaction or provide any Service you have requested, we may
              share your information with our affiliates, contracted companies,
              and service providers (collectively, “Service Providers”) we rely
              upon to assist in the operation of the Mobile Application and
              Services available to you and whose privacy policies are
              consistent with ours or who agree to abide by our policies with
              respect to Personal Information. We will not share any personally
              identifiable information with third parties and will not share any
              information with unaffiliated third parties. Service Providers are
              not authorized to use or disclose your information except as
              necessary to perform services on our behalf or comply with legal
              requirements. Service Providers are given the information they
              need only in order to perform their designated functions, and we
              do not authorize them to use or disclose any of the provided
              information for their own marketing or other purposes. We will
              share and disclose your information only with the following
              categories of Service Providers:
            </p>
            <li>Ad Networks Data analytics services</li>
          </ul>
        </div>
        <div className="share">
          <h5>Push Notifications</h5>
          <ul>
            <li>
              • We offer push notifications to which you may voluntarily
              subscribe at any time. To make sure push notifications reach the
              correct devices, we rely on a device token unique to your device
              which is issued by the operating system of your device. While it
              is possible to access a list of device tokens, they will not
              reveal your identity, your unique device ID, or your contact
              information to us. We will maintain the information sent via
              e-mail in accordance with applicable laws and regulations. If, at
              any time, you wish to stop receiving push notifications, simply
              adjust your device settings accordingly.
            </li>
          </ul>
        </div>
        <div>
          <h5>Data Breach</h5>
          <ul>
            <li>
              • In the event that we become aware of a security breach affecting
              the Mobile Application and Services, resulting in the unauthorized
              disclosure of Users' Personal Information due to external factors
              such as security attacks or fraudulent activities, we retain the
              right to take appropriate actions. These actions may include
              conducting an investigation, cooperating with law enforcement
              authorities, and making necessary reports. If a data breach
              occurs, we will make reasonable efforts to notify affected
              individuals when we believe there is a reasonable risk of harm to
              the User due to the breach, or if such notification is mandated by
              applicable law. When this occurs, we will promptly post a notice
              within the Mobile Application and send an email notification. Your
              privacy and data security are of utmost importance to us, and we
              are committed to taking all necessary steps to safeguard your
              information.
            </li>
          </ul>
          <h4>Cookies</h4>
          <ul>
            <li>
              • The Website and Services use "cookies" to help personalize your
              online experience. A cookie is a text file that is placed on your
              hard disk by a web page server. Cookies cannot be used to run
              programs or deliver viruses to your computer. Cookies are uniquely
              assigned to you, and can only be read by a web server in the
              domain that issued the cookie to you.
            </li>
            <li>
              • We may use cookies to collect, store, and track information for
              statistical purposes to operate the Website and Services. You have
              the ability to accept or decline cookies. Most web browsers
              automatically accept cookies, but you can usually modify your
              browser setting to decline cookies if you prefer. If you choose to
              decline cookies, you will not be able to use and experience the
              features of the Website and Services.
            </li>
          </ul>
        </div>
        <div>
          <h5>Automatically Collected Information:</h5>
          <p>How We Use Information:</p>
          <ul>
            <li>
              • We use information we have (subject to choices you make and
              applicable law) to operate, provide, improve, understand,
              customize, support, and market our Services. Here's how our
              Services. We use information we have to operate and provide our
              Services, including providing customer support, completing
              purchases or transactions, improving, fixing and customizing our
              Services and connecting our Services with that you may use. We
              also use information we have to understand how people use our
              Services, evaluate and improve our Services, research, develop,
              and test new services and features and conduct troubleshooting
              activities. We also use your information to respond to you when
              you contact us.
            </li>
          </ul>
          <h6>Law, Our Rightsand Protection:</h6>
          <ul>
            <li>
              • We access, preserve and share your information described in the
              "Information We Collect" section of this Privacy Policy above if
              we have a good-faith belief that it is necessary to: (a) respond
              pursuant to applicable law or regulations, legal process, or
              government requests; (b) enforce our Terms and any other
              applicable terms and policies, including for investigations of
              potential violations; (c) detect, investigate, prevent, or address
              fraud and other illegal activity or security, and technical
              issues; or (d) protect the rights, property, and safety of our
              users, Payzoor others, including to prevent death or imminent
              bodily harm.
            </li>
          </ul>
        </div>
        <div>
          <h5>Links to other resources:</h5>

          <ul>
            <li>
              • The Website and Services contain links to other resources that
              are not owned or controlled by us. Please be aware that we are not
              responsible for the privacy practices of such other resources or
              third parties. We encourage you to be aware when you leave the
              Website and Services and to read the privacy statements of each
              and every resource that may collect Personal Information.
            </li>
          </ul>
        </div>

        <div className="cookie">
          <h4>Acceptance of this policy:</h4>
          <ul>
            <li>
              You acknowledge that you have read this Policy and agree to all
              its terms and conditions. By accessing and using the Website and
              Services you agree to be bound by this Policy. If you do not agree
              to abide by the terms of this Policy, you are not authorized to
              access or use the Website and Services.
            </li>
          </ul>
        </div>
        <div>
          <h5>Updates to Our Policy:</h5>

          <ul>
            <li>
              We may amend or update our Privacy Policy. We will provide you
              notice of amendments to this Privacy Policy, as appropriate, and
              update the “Last modified” date at the top of this Privacy Policy.
              Please review our Privacy Policy from time to time.
            </li>
          </ul>
        </div>

        <div>
          <h5>Contacting us:</h5>
          <ul>
            <li>
              If you have any questions, concerns, or complaints regarding this
              Policy, the information we hold about you, or if you wish to
              exercise your rights, we encourage you to contact us using the
              details below:
            </li>
            <li>hello@payzopartner.com</li>
            <li>
              We will attempt to resolve complaints and disputes and make every
              reasonable effort to honour your wish to exercise your rights as
              quickly as possible and in any event, within the timescales
              provided by applicable data protection laws.
            </li>
            <li>Last updated Privacy Policy on 24th August 2024.</li>
          </ul>
        </div>
      </div>
    </div>
  );
}

export default PrivacyPolicy;
