import React, {useState, useEffect} from 'react';
import "../styles/screensignup.scss";
import axios from 'axios';
import { toast , ToastContainer} from 'react-toastify';
import { Navigate, useLocation, useNavigate } from 'react-router-dom';
function ScreenSignup() {

 const [fullName, setFullName] = useState("");
 const [Password, setPassword] = useState("");
 const [ConfirmPassword, setConfirmPassword] = useState("");
 const [Email, setEmail] = useState("");
 const [Dob, setDob] = useState("");
 const location  = useLocation();
 const navigate = useNavigate();

 useEffect(()=>{
 
     const handlePopState = () => {
   // Force a redirect to prevent going back to the profile page
   navigate("/", { replace: true });
 };
 //     // Continuously push the current state every time the user navigates away
 const intervalId = 
   window.history.pushState(null,null, '/');
  
 
 // Cleanup the event listener and interval on component unmount
 return () => {
   clearInterval(intervalId);
   window.removeEventListener("popstate", handlePopState);
 };
 })

 const PhoneId = location.state?.phoneId;
 const PhoneNumberTakeUp = location.state?.PhoneNumber;

    const handleUpadate = async()=> {

        try {
            const updateresponse = await axios.post('https://vendor.payzopartner.in/payzo-api/updatephonestatus', {
                'phone_id' : PhoneId,
                'status' :"yes"
            })
            if(updateresponse.data.success === true){
                toast.success("Mobile Validation Updated Successfully");
            }else{
                toast.error("Mobile validation Error");
            }
        } catch (error) {
           console.log(error); 
        }
    }

    const handleRegisterUser = async()=> {
   
        try {
            const registerresponse = await axios.post('https://vendor.payzopartner.in/payzo-api/vendorsignup', {
                'full_name' : fullName,
                'phone_number' : PhoneNumberTakeUp,
                'password' : Password,
                'confirm_password' : ConfirmPassword,
                'email' : Email,
                'dob' : Dob,
                "otpverifieduser" : "no"
            })
            if(registerresponse.data.success === true){
                toast.success("Registration Successfull")
                handleUpadate();
                setTimeout(()=> {
                 navigate('/screen-login')
                }, 3000)
            }
        } catch (error) {
            toast.error("Alredy registered");
            setTimeout(()=> {
                navigate('/screen-login')
               }, 1000)

        }
    }

    return (
        <div>
            <ToastContainer/>
            <div className='screensignup-main-container'>
                <div className='screensignup-card'>
                    <h1>Vendor Signup</h1>
                        <h6 style={{color:'black'}}>Your Mobile Number : {PhoneNumberTakeUp}</h6>
                      <div className='box-column'>
                         <h6 className='h6'>Full Name</h6>
                         <input value={fullName} onChange={(e)=> setFullName(e.target.value)} className='signup-inputs' type='text' placeholder='Enter Full Name' />
                      </div>
                      {/* <div className='box-column'>
                         <h6 className='h6'>Phone Number</h6>
                         <input  className='signup-inputs' type='phone' placeholder='Enter Phone Number' />
                      </div> */}
                      <div className='box-column'>
                         <h6 className='h6'>password</h6>
                         <input value={Password} onChange={(e)=> setPassword(e.target.value)} className='signup-inputs' type='password' placeholder='Enter Password' />
                      </div>
                      <div className='box-column'>
                         <h6 className='h6'> Confirm password</h6>
                         <input value={ConfirmPassword}  onChange={ (e)=> setConfirmPassword(e.target.value)}className='signup-inputs' type='password' placeholder='Enter Confirm Password' />
                      </div>
                      <div className='box-column'>
                         <h6 className='h6'> Email</h6>
                         <input value={Email} onChange={(e)=> setEmail(e.target.value)} className='signup-inputs' type='email' placeholder='Enter Email' />
                      </div>
                       <div className='box-column'>
                         <h6 className='h6'> Date of Birth</h6>
                         <input value={Dob} onChange={(e)=> setDob(e.target.value)}  className='signup-inputs' type='date' placeholder='Enter Email' />
                      </div> 
                    <button className='register-btn' onClick={handleRegisterUser}>Register</button>
                </div>
            </div>
        </div>
    );
}

export default ScreenSignup;
