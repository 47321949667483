import React,{useState, useEffect} from "react";
import "../styles/screenlogin.scss";
import PayzoLogo from "../../src/components/vendor_screens/vendor_images/vendorpayzo.png";
import axios from "axios";
import { toast, ToastContainer } from "react-toastify";
import { useNavigate } from "react-router-dom";

function ScreenLogin(){
const [MobileNumber, setMobileNumber] = useState("");
const [password, setPassword] = useState("");
const navigate = useNavigate();

useEffect(()=>{

    const handlePopState = () => {
        window.localStorage.removeItem("PAYZOWEBSITEENCRYPTION");
  navigate("/", { replace: true });
};
//     // Continuously push the current state every time the user navigates away
const intervalId = 
  window.history.pushState(null,null, '/');
 

// Cleanup the event listener and interval on component unmount
return () => {
  clearInterval(intervalId);
  window.removeEventListener("popstate", handlePopState);
};
})


const handleLoginIntoVendor = async(e)=> {
    e.preventDefault();
    try {
        const response  = await axios.post('https://vendor.payzopartner.in/payzo-api/vendorlogin', {
            'phone_number' : MobileNumber,
            'confirm_password' : password
        })
        if(response.data.success === true){
            var VendorId = response.data.User[0].vendor_id;
            var fullName = response.data.User[0].full_name;
            // console.log(userId  + "Deleep your User Id is ")
             toast.info("Login Success");
                    window.localStorage.setItem("PAYZOWEBSITEENCRYPTION", "JWT4256142RRB")
                    navigate("/vendor-profile", {
                      state: { VendorId, fullName }
                    });
                }else{
                    toast.error("Invalid Mobile Number or Password");
                }
    } catch (error) {
       toast.error("Invalid MobileNumber or Password" );
    }
}
   
    
    return(
        <div>
            <ToastContainer/>
          <div className="loginscreen-main-container">
              <div className="screenlogin-card">
                <img className="payzo-logo" src={PayzoLogo} alt="" />
                 <h2>Vendor Login</h2>
                 <input value={MobileNumber} onChange={(e)=> setMobileNumber(e.target.value)} className="logininputters" type="text" placeholder="Please Enter Mobile Number" />
                 <input value={password} onChange={(e)=> setPassword(e.target.value)} className="logininputters" type="password" placeholder="Please Enter Password" />
                 {/* <p className="frgt" onClick={()=> naviagate('/forgot-password-vendor')}>Forgot Password ?</p> */}
                 <button className="vendorlogin" onClick={handleLoginIntoVendor}>Login</button>
                 <p style={{cursor:'pointer'}} onClick={()=>navigate('/screen-one') }>Create New Account ? </p>
              </div>
          </div>
        </div>
    )
}

export default ScreenLogin;