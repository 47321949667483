import React from "react";
import { Navigate, Outlet } from "react-router-dom";

const PrivateRoute = ()=> {
    const isLoggedIn = window.localStorage.getItem("PAYZOWEBSITEENCRYPTION");
    if(isLoggedIn === "JWT4256142RRB"){
        return <Outlet/>
    }else {
        return <Navigate to="/screen-login" replace/>
    }
}

export default PrivateRoute;