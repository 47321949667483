import React, { useState, useEffect } from "react";
import axios from "axios";
import { useLocation, useNavigate } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import "../vendor_screens/vendor_style.scss";
import payzologoupdate from "../vendor_screens/vendor_images/vendorpayzo.png";
import { FaRegHandPointRight } from "react-icons/fa6";

function VendorUpdateBusiness() {
  const [businessData, setBusinessData] = useState([]);
  const [editedBusiness, setEditedBusiness] = useState(null);
  const [selectCategory, setSelectCategory] = useState("");
  const navigate = useNavigate();
  const location = useLocation();
  const userId = location.state?.userId;

  useEffect(() => {
    if (userId) {
      axios
        .get(
          `https://api.payzopartner.com/payzo-api/getBusinessesByUserId/${userId}`
        )
        .then((res) => {
          if (Array.isArray(res.data.businesses)) {
            setBusinessData(res.data.businesses);
          } else {
            console.error(
              "Data received is not an array:",
              res.data.businesses
            );
          }
        })
        .catch((err) => {
          console.error("Error fetching business data:", err);
        });
    }
  }, [userId]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setEditedBusiness((prevState) => ({
      ...prevState,
      [name]: value
    }));
  };

  const handleEdit = (item) => {
    setEditedBusiness(item);
  };
    // Handle Logout
    const handleLogut = (e)=> {
      e.preventDefault();
      window.localStorage.removeItem("PAYZOWEBSITEENCRYPTION");
      navigate("/vendor-login")
    }
  

  const handleSubmit = (e) => {
    e.preventDefault();
    if (editedBusiness) {
      axios
        .post(
          "https://api.payzopartner.com/payzo-api/updateBusiness",
          editedBusiness
        )
        .then((res) => {
          console.log("Update response:", res);
          toast.success("Business Updated successfully");
          // Optionally refetch the data or reset the editedBusiness state
          setEditedBusiness(null);
        })
        .catch((err) => {
          console.error("Error updating business data:", err);
          toast.error("Error updating business");
        });
    }
  };

  return (
    <div>
      <ToastContainer />
      <div className="update-business-navbar">
        <img
          className="payzo-logo-update"
          src={payzologoupdate}
          alt="Payzo Logo"
        />
      </div>
      <div className="update-business-main-container">
        <div className="update-business-card-maintainer">
          {Array.isArray(businessData) &&
            businessData.map((item, index) => (
              <div key={item.id || index} className="update-business-card">
                <div>
                  <img
                    className="update-business-image"
                    src={item.profile_image}
                    alt={item.business_name}
                  />
                </div>
                <div className="update-business-cards-details">
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "center",
                      gap: "1rem",
                      alignItems: "center"
                    }}
                  >
                    <p className="parent-title">
                      Business Name <FaRegHandPointRight />
                    </p>
                    <p className="child-title">{item.business_name}</p>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "center",
                      gap: "1rem",
                      alignItems: "center"
                    }}
                  >
                    <p className="parent-title">
                      Business Type <FaRegHandPointRight />
                    </p>
                    <p className="child-title">{item.business_type}</p>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "center",
                      gap: "1rem",
                      alignItems: "center"
                    }}
                  >
                    <p className="parent-title">
                      Vendor Name <FaRegHandPointRight />
                    </p>
                    <p className="child-title">{item.vendor_name}</p>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "center",
                      gap: "1rem",
                      alignItems: "center"
                    }}
                  >
                    <p className="parent-title">
                      phone Number <FaRegHandPointRight />
                    </p>
                    <p className="child-title">{item.phone_number}</p>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "center",
                      gap: "1rem",
                      alignItems: "center"
                    }}
                  >
                    <p className="parent-title">
                      Address <FaRegHandPointRight />
                    </p>
                    <p className="child-title">{item.address}</p>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "center",
                      gap: "1rem",
                      alignItems: "center"
                    }}
                  >
                    <p className="parent-title">
                      Email <FaRegHandPointRight />
                    </p>
                    <p className="child-title">{item.email}</p>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "center",
                      gap: "1rem",
                      alignItems: "center"
                    }}
                  >
                    <p className="parent-title">
                      Pincode <FaRegHandPointRight />
                    </p>
                    <p className="child-title">{item.pincode}</p>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "center",
                      gap: "1rem",
                      alignItems: "center"
                    }}
                  >
                    <p className="parent-title">
                      Google Map link <FaRegHandPointRight />
                    </p>
                    <p className="child-title">{item.google_map_link}</p>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "center",
                      gap: "1rem",
                      alignItems: "center"
                    }}
                  >
                    <p className="parent-title">
                      Category Name <FaRegHandPointRight />
                    </p>
                    <p className="child-title">{item.category_name}</p>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "center",
                      gap: "1rem",
                      alignItems: "center"
                    }}
                  >
                    <p className="parent-title">
                      City Name <FaRegHandPointRight />
                    </p>
                    <p className="child-title">{item.city_name}</p>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "center",
                      gap: "1rem",
                      alignItems: "center"
                    }}
                  >
                    <p className="parent-title">
                      State Name <FaRegHandPointRight />
                    </p>
                    <p className="child-title">{item.state_name}</p>
                  </div>
                </div>

                <button
                  className="edit-business-button"
                  onClick={() => handleEdit(item)}
                >
                  Edit Business
                </button>
              </div>
            ))}
        </div>

        <div className="update-business-fotter-container">
          {editedBusiness && (
            <div className="internal-update-business">
              <form onSubmit={handleSubmit}>
                <div
                  style={{ display: "flex", flexDirection: "row", gap: "1rem" }}
                >
                  <span>Business Name</span>
                  <input
                    style={{ width: "100% ", height: "2rem" }}
                    type="text"
                    name="business_name"
                    placeholder="Enter Business Name"
                    value={editedBusiness.business_name || ""}
                    onChange={handleInputChange}
                  />
                </div>

                <div
                  style={{ display: "flex", flexDirection: "row", gap: "1rem" }}
                >
                  <span>Vendor Name</span>
                  <input
                    style={{ width: "100%", height: "2rem" }}
                    type="text"
                    name="vendor_name"
                    placeholder="Enter Vendor Name"
                    value={editedBusiness.vendor_name || ""}
                    onChange={handleInputChange}
                  />
                </div>

                <div
                  style={{ display: "flex", flexDirection: "row", gap: "1rem" }}
                >
                  <span>Business type</span>
                  <select
                    name="business_type"
                    value={editedBusiness.business_type || ""}
                    onChange={handleInputChange}
                  >
                    <option value="offline">offline</option>
                    <option value="online">online</option>
                  </select>
                </div>

                <div
                  style={{ display: "flex", flexDirection: "row", gap: "1rem" }}
                >
                  <span>Address</span>
                  <input
                    style={{ width: "100%", height: "2rem" }}
                    type="text"
                    name="address"
                    placeholder="Please enter a Address"
                    value={editedBusiness.address || ""}
                    onChange={handleInputChange}
                  />
                </div>

                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    gap: "1rem",
                    marginTop: "1rem"
                  }}
                >
                  <span>Email</span>
                  <input
                    style={{ width: "100%", height: "2rem" }}
                    type="text"
                    name="email"
                    placeholder="Please enter a Email"
                    value={editedBusiness.email || ""}
                    onChange={handleInputChange}
                  />
                </div>

                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    gap: "1rem",
                    marginTop: "1rem"
                  }}
                >
                  <span>pincode</span>
                  <input
                    style={{ width: "100%", height: "2rem" }}
                    type="text"
                    name="pincode"
                    placeholder="Please enter a pincode"
                    value={editedBusiness.pincode || ""}
                    onChange={handleInputChange}
                  />
                </div>

                <div
                  style={{ display: "flex", flexDirection: "row", gap: "1rem" }}
                >
                  <span>Google Map link</span>
                  <input
                    style={{ width: "100%", height: "2rem" }}
                    type="text"
                    name="google_map_link"
                    placeholder="Please enter a Google Map link"
                    value={editedBusiness.google_map_link || ""}
                    onChange={handleInputChange}
                  />
                </div>

                <div
                  style={{ display: "flex", flexDirection: "row", gap: "1rem" }}
                >
                  <span>Phone Number</span>
                  <input
                    style={{ width: "100%", height: "2rem" }}
                    type="text"
                    name="phone_number"
                    placeholder="Please enter a phone number"
                    value={editedBusiness.phone_number || ""}
                    onChange={handleInputChange}
                  />
                </div>

                <div
                  style={{ display: "flex", flexDirection: "row", gap: "1rem" }}
                >
                  <span>Category Name</span>
                  <input
                    style={{ width: "100%", height: "2rem" }}
                    type="text"
                    name="category_name"
                    placeholder="Please enter a Category Name"
                    value={editedBusiness.category_name || ""}
                    onChange={handleInputChange}
                  />
                </div>

                <div
                  style={{ display: "flex", flexDirection: "row", gap: "1rem" }}
                >
                  <span>City Name</span>
                  <input
                    style={{ width: "100%", height: "2rem" }}
                    type="text"
                    name="city_name"
                    placeholder="Please enter a City Name"
                    value={editedBusiness.city_name || ""}
                    onChange={handleInputChange}
                  />
                </div>

                <div
                  style={{ display: "flex", flexDirection: "row", gap: "1rem" }}
                >
                  <span>State Name</span>
                  <input
                    style={{ width: "80%", height: "2rem" }}
                    type="text"
                    name="state_name"
                    placeholder="Please enter a State Name"
                    value={editedBusiness.state_name || ""}
                    onChange={handleInputChange}
                  />
                </div>

                <div
                  style={{
                    width: "100%",
                    height: "2rem",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center"
                  }}
                >
                  <button type="submit" className="edit-business-button">
                    Update Business
                  </button>
                </div>
              </form>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default VendorUpdateBusiness;
