import React, { useEffect, useState } from "react";
import "../vendor_screens/vendor_style.scss";
import VendorPayzologo from "../vendor_screens/vendor_images/vendorpayzo.png";
import Offcanvass from "../off_canvas";
import { MdOutlineNotificationsActive } from "react-icons/md";
import { FaUserLarge } from "react-icons/fa6";
import DropDown from "../dropdown";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";

function VendorProfile() {
  const [selectvalue, setSelectValue] = useState("");
  const [businessName, setBusinessName] = useState("");
  const [vendorName, setVendorName] = useState("");
  const [storetimings, setStoreTimings] = useState("");
  const [whatsappNumber, setWhatsappNumber] = useState("");
  const [Category, setCategory] = useState([]);
  const [storeCategory, setStoreCategory] = useState("");
  const [emailAddress, setEmailAddress] = useState("");
  const [businessAddress, setBusinessAddress] = useState("");
  const [areaPincode, setAreaPincode] = useState("");
  const [state, setState] = useState([]);
  const [selectedState, setSelectedState] = useState("");
  const [cities, setCities] = useState([]);
  const [selectedCity, setSelectedCity] = useState("");
  const [googleMapLink, setGoogleMapLink] = useState("");
  const [documentTitle, setDocumentTitle] = useState("");
  const [socialMediaLink, setSocialMediaLink] = useState("");
  const [kycDocument, setKycDocument] = useState("");
  const [businessImage, setBusinessImage] = useState("");
  const [kycImage, setKycImage] = useState("");
  const [promoCode, setPromoCode] = useState("");
  const [approvalStatus, SetApprovalStatus] = useState("pending");
  const [remark, setRemark] = useState("onhold");
  const [updateBusinessData, setUpdateBusinessData] = useState([]);
  const location = useLocation();
  const navigate = useNavigate();

  const VendorId = location.state?.VendorId;
  const userName = location.state?.fullName?.toUpperCase();
  const businessId = location.state?.businessId;




//validators start here...>
const handlePincodeChange = (e) => {
  const value = e.target.value;
  if (/^\d{0,6}$/.test(value)) {  
    setAreaPincode(e.target.value)
  }
};

const handleMobileNumberChange = (e)=> {
  const value = e.target.value;
  if (/^\d{0,10}$/.test(value)) {  
    setWhatsappNumber(e.target.value)
  }
}


  const handleImageChange = (e, setImage) => {
    if (e.target.files && e.target.files[0]) {
      setImage(e.target.files[0]);
    }
  };

  const handlePromoCode = async (e) => {
    e.preventDefault();
    try {
      const PromocodeData = await axios.post(
        "https://api.payzopartner.com/payzo-api/verifyPromoCode",
        {
          promoCode: promoCode
        }
      );
      toast.warning("Status :" + " " + PromocodeData.data.message);
    } catch (error) {
      console.log(error);
    }
  };




  const handleSubmitBusiness = async (e) => {
    e.preventDefault();
    const isValidURL = (url) => {
      try {
        new URL(url); 
        return true;
      } catch (e) {
        return false;
      }
    };
    
    const errors = [];
    if (!businessName) errors.push("Please Enter Business Name");
    if (!businessAddress) errors.push("Please Enter Business Address");
    if (!storetimings) errors.push("Please mention Store Timings ex:- 9:00Am to 12:00PM");
    if (!vendorName) errors.push("Please enter vendor Name");
    if (!whatsappNumber) errors.push("Please Enter Mobile Number");
    if (!storeCategory) errors.push("Please Enter Store Category");
    if (!emailAddress) errors.push("Please enter email address");
    if (!areaPincode) errors.push("Please enter Pincode");
    if (!selectedState) errors.push("Please select State");
    if (!selectedCity) errors.push("Please Select City");
    if (!documentTitle) errors.push("Please mention document Title like Aadhar, Pan");
    if (!kycDocument) errors.push("Please fill Document Id Number");
    if (!businessImage) errors.push("Please Upload Business Image");
    if (!kycImage) errors.push("Please upload Shop Registration or GVMC Approval");
    if (!selectvalue) errors.push("Please select Business type");
    if (selectvalue === "offline" && !googleMapLink) errors.push("Please fill Google Map Link");
    if (selectvalue === "online" && !socialMediaLink) errors.push("Please fill Website Link");
    if (googleMapLink && !isValidURL(googleMapLink)) errors.push("please Provide URL Only in Google Map Link");
    if (errors.length > 0) {
      errors.forEach((error) => toast.error(error));
      return; // Prevent further submission if validation fails
    }
  
  
    const formData = new FormData();
    formData.append("business_type", selectvalue);
    formData.append("business_name", businessName);
    formData.append("address", businessAddress);
    formData.append("email", emailAddress);
    formData.append("phone_number", whatsappNumber);
    formData.append("pincode", areaPincode);
    formData.append("state_id", selectedState);
    formData.append("city_id", selectedCity);
    formData.append("category_id", storeCategory);
    formData.append("store_timing", storetimings);
    formData.append("vendor_id", VendorId);
    formData.append("google_map_link", googleMapLink);
    formData.append("vendor_name", vendorName);
    formData.append("promo_code", promoCode);
    formData.append("website_link", socialMediaLink);
    formData.append("documentId", kycDocument);
    formData.append("approval_status", approvalStatus);
    formData.append("remark", remark);
  
    if (businessImage) formData.append("profile_image", businessImage);
    if (kycImage) formData.append("kyc_doc_image", kycImage);
    formData.append("documentTitle", documentTitle);
  
    try {
      const AddBusiness = await axios.post("https://vendor.payzopartner.in/payzo-api/addBusiness", formData, {
        headers: { "Content-Type": "multipart/form-data" }
      });
      
      if (AddBusiness.data?.success) {
        toast.success("Business registered successfully");
        setTimeout(() => {
          navigate("/my-business", { state: { VendorId, businessId } });
        }, 1000);
      } else {
        toast.error("Error registering business");
      }
    } catch (error) {
      toast.error("Please Verify all fields");
    }
  };
  


  //update-Business data
  useEffect(() => {
    if (businessId) {
      axios
        .get(
          `https://api.payzopartner.com/payzo-api/getBusinessesByUserId/${VendorId}`
        )
        .then((res) => {
          if (Array.isArray(res.data.data)) {
            setUpdateBusinessData(res.data.data);
            const businessData = res.data.data[0];
            setSelectValue(businessData.business_type);
            setBusinessName(businessData.business_name);
            setVendorName(businessData.vendor_name);
            setStoreTimings(businessData.store_timing);
            setWhatsappNumber(businessData.phone_number);
            setCategory(businessData.category_id);
            setStoreCategory(businessData.category_id);
          }
        })
        .catch((error) =>
          console.error("Error fetching business data:", error)
        );
    }
  }, [businessId, VendorId]);

  useEffect(() => {
    axios
      .get("https://vendor.payzopartner.in/payzo-api/getAllbusinesscategory")
      .then((res) => {
        console.log("category response" + res);
        if (Array.isArray(res.data.data)) {
          setCategory(res.data.data);
        } else {
          console.log("error occurred in category");
        }
      });
  }, []);

  useEffect(() => {
    axios
      .get("https://vendor.payzopartner.in/payzo-api/getStates")
      .then((res) => {
        console.log("API response for states:", res);
        if (Array.isArray(res.data.data)) {
          setState(res.data.data);
        } else {
          console.error("Expected an array but got:", res.data.data);
        }
      })
      .catch((error) => {
        console.error("There was an error fetching the states!", error);
      });
  }, []);

  useEffect(() => {
    if (selectedState) {
      axios
        .post("https://vendor.payzopartner.in/payzo-api/getCities", {
          state_id: selectedState
        })
        .then((res) => {
          console.log("API response for cities:", res);
          if (Array.isArray(res.data.data)) {
            setCities(res.data.data);
          } else {
            console.error("Expected an array but got:", res.data.data);
          }
        })
        .catch((error) => {
          console.error("There was an error fetching the cities!", error);
        });
    }
  }, [selectedState]);

  return (
    <div className="vendor-main">
        <ToastContainer />
      <div className="Business-add-Navbar" >
        <div  className="img-offcanva">
          <p>
            <Offcanvass  VendorId={VendorId}/>
          </p>
          <img className="Business-payzo-logo" src={VendorPayzologo} alt="" />
        </div>
        <div className="two-icon">
        
            <DropDown />
          
        </div>
      </div>

      <div className="Business-add-container">
        <div className="vendor-Business-card">
          <div className="Add-Business-navbar">
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                fontSize: "1.3rem",
                fontWeight: "600"
              }}
            >
              <h2 style={{fontWeight:'700'}}>MY BUSINESS PROFILE</h2>
            </div>
           
            <p style={{fontFamily:"sans-serif", fontWeight:'700', color:'orange', fontSize:'1rem', display:'flex', justifyContent:'center'}}>WELCOME {userName}</p>
            {/* <p>Your Business Id : {businessId}</p>
            <p>User Id : {userId}</p> */}
          </div>
          <div className="Business-uploaders">
            <div className="onof-selector">
              <div className="select-Bus">
                <p className="select-Bus-font">Select Business Type</p>
                <select
                  value={selectvalue}
                  onChange={(e) => setSelectValue(e.target.value)}
                  className="bs-selector"
                >
                  <option value="">Select Business Type</option>
                  <option value="online">online</option>
                  <option value="offline">offline</option>
                </select>
              </div>

              {/* <div className="Promo-controller">
                <h3 className="basu">Enter Promo Code</h3>
                <input
                  className="promo-filler"
                  type="text"
                  placeholder="Enter Promo Code"
                  value={promoCode}
                  onChange={(e) => setPromoCode(e.target.value)}
                />
                <button className="promo-verify" onClick={handlePromoCode}>
                  Verify
                </button>
              </div> */}
            </div>

            <div className="input-tolerates">
              <div className="input-home">
                <div className="input-ciculate">
                  <div style={{ display: "flex", flexDirection: "row" , fontWeight:'700'}}>
                    <p>Business Name</p>
                    <span style={{ color: "red" }}>*</span>
                  </div>

                  <input
                    value={businessName}
                    onChange={(e) => setBusinessName(e.target.value)}
                    className="input-business"
                    type="text"
                    placeholder="Enter Business name"
                  />
                </div>
                <div className="input-ciculate">
                  <div style={{ display: "flex", flexDirection: "row", fontWeight:'700' }}>
                    <p>Store Timings</p>
                    <span style={{ color: "red" }}>*</span>
                  </div>
                  <input
                    value={storetimings}
                    onChange={(e) => setStoreTimings(e.target.value)}
                    className="input-business"
                    type="text"
                    placeholder="HH:MM AM-PM"
                  />
                </div>
                <div className="input-ciculate">
                  <div style={{ display: "flex", flexDirection: "row", fontWeight:'700' }}>
                    <p>Vendor Name</p>
                    <span style={{ color: "red" }}>*</span>
                  </div>
                  <input
                    value={vendorName}
                    onChange={(e) => setVendorName(e.target.value)}
                    className="input-business"
                    type="text"
                    placeholder="Enter Vendor name"
                  />
                </div>
                <div className="input-ciculate">
                  <div style={{ display: "flex", flexDirection: "row", fontWeight:'700'}}>
                    <p>Mobile Number</p>
                    <span style={{ color: "red" }}>*</span>
                  </div>
                  <input
                    value={whatsappNumber}
                    onChange={handleMobileNumberChange}
                    className="input-business"
                    type="text"
                    placeholder="Enter Mobile Number"
                   maxLength={10}
                   style={{
                   WebkitBackgroundClip:"none"
                   }}
                  />
                </div>
                <div className="input-ciculate">
                  <div style={{ display: "flex", flexDirection: "row", fontWeight:'700' }}>
                    <p>Store Category</p>
                    <span style={{ color: "red" }}>*</span>
                  </div>
                  <select
                    value={storeCategory}
                    onChange={(e) => setStoreCategory(e.target.value)}
                    className="input-businesss"
                  >
                    <option value="">Select Category</option>
                    {Category.map((item, index) => (
                      <option key={index} value={item.id}>
                        {item.name}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="input-ciculate">
                  <div style={{ display: "flex", flexDirection: "row", fontWeight:'700' }}>
                    <p>Email Address</p>
                    <span style={{ color: "red" }}>*</span>
                  </div>
                  <input
                    value={emailAddress}
                    onChange={(e) => setEmailAddress(e.target.value)}
                    className="input-business"
                    type="text"
                    placeholder="Enter Email Address"
                  />
                </div>
                <div className="input-ciculate">
                  <div style={{ display: "flex", flexDirection: "row" , fontWeight:'700'}}>
                    <p>Business Address</p>
                    <span style={{ color: "red" }}>*</span>
                  </div>
                  <input
                    value={businessAddress}
                    onChange={(e) => setBusinessAddress(e.target.value)}
                    className="input-business"
                    type="text"
                    placeholder="Enter Business Address"
                  />
                </div>
                <div className="input-ciculate">
                  <div style={{ display: "flex", flexDirection: "row", fontWeight:'700' }}>
                    <p>Area Pincode</p>
                    <span style={{ color: "red" }}>*</span>
                  </div>
                  <input
                    value={areaPincode}
                    onChange={handlePincodeChange}
                    className="input-business"
                    type="phone"
                    
                    placeholder="Enter Pincode"
                    maxLength={6}
                  />
                </div>
                <div className="input-ciculate">
                  <div style={{ display: "flex", flexDirection: "row", fontWeight:'700' }}>
                    <p>Select State</p>
                    <span style={{ color: "red" }}>*</span>
                  </div>
                  <select
                    value={selectedState}
                    onChange={(e) => setSelectedState(e.target.value)}
                    className="input-businesss"
                  >
                    <option value="" >
                      Select a state
                    </option>
                    {state.map((item, index) => (
                      <option key={index} value={item.id}>
                        {item.name}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="input-ciculate">
                  <div style={{ display: "flex", flexDirection: "row", fontWeight:'700' }}>
                    <p>Select City</p>
                    <span style={{ color: "red" }}>*</span>
                  </div>
                  <select
                    value={selectedCity}
                    onChange={(e) => setSelectedCity(e.target.value)}
                    className="input-businesss"
                  >
                    <option value="" >
                      Select a city
                    </option>
                    {cities.map((item, index) => (
                      <option key={index} value={item.id}>
                        {item.name}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="input-ciculate">
                  <div style={{ display: "flex", flexDirection: "row" , fontWeight:'700'}}>
                    <p>Google Map Link</p>
                    {/* <span style={{ color: "red" }}>*</span> */}
                  </div>
                  <input
                    value={googleMapLink}
                    onChange={(e) => setGoogleMapLink(e.target.value)}
                    className="input-business"
                    type="text"
                    placeholder="Enter Google Map Link"
                  />
                </div>

                <div className="input-ciculate">
                  <div style={{ display: "flex", flexDirection: "row", fontWeight:'700' }}>
                    <p>Website link</p>
                    {/* <span style={{ color: "red" }}>*</span> */}
                  </div>
                  <input
                    value={socialMediaLink}
                    onChange={(e) => setSocialMediaLink(e.target.value)}
                    className="input-business"
                    type="text"
                    placeholder="Social media link ex:- payzo.com" 
                  />
                </div>

                <div className="input-ciculate">
                  <div style={{ display: "flex", flexDirection: "row", fontWeight:'700' }}>
                    <p> KYC Document Title</p>
                    <span style={{ color: "red" }}>*</span>
                  </div>
                  <input
                    value={documentTitle}
                    onChange={(e) => setDocumentTitle(e.target.value)}
                    className="input-business"
                    type="text"
                    placeholder="Enter Document Name"
                  />
                </div>
                <div className="input-ciculate">
                  <div style={{ display: "flex", flexDirection: "row", fontWeight:'700' }}>
                    <p>KYC Document Id</p>
                    <span style={{ color: "red" }}>*</span>
                  </div>
                  <input
                    value={kycDocument}
                    onChange={(e) => setKycDocument(e.target.value)}
                    className="input-business"
                    type="text"
                    placeholder="Enter KYC Doument Id ex: PANXXXXE"
                  />
                </div>
              </div>
            </div>
            <div className="uploaders-section">
              <div className="Business-uploader-section">
                <div style={{
                  padding:"2rem",
                  gap:"2rem"
                }} className="uploader-1">
                  <input
                    type="file"
                    onChange={(e) => handleImageChange(e, setBusinessImage)}
                  />
                  <p>Upload Business Image</p>
                </div>
                <div style={{
                  padding:"2rem",
                  gap:"2rem"
                }}className="uploader-1">
                  <input
                    type="file"
                    onChange={(e) => handleImageChange(e, setKycImage)}
                  />
                  <p>Kyc Document Image</p>
                </div>
              </div>
              <div className="submit-containers" style={{padding:'1rem'}}>
                <button
                  className="Business-add-submit"
                  onClick={handleSubmitBusiness}
                >
                  Submit
                </button>
                {/* <button
                  className="Business-add-submit"
                  onClick={handleUpdateBusiness}
                >
                  Update{" "}
                </button> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default VendorProfile;
