import React, { useState, useEffect } from 'react';
import "../styles/screensignup.scss";
import axios from 'axios';
import { toast, ToastContainer } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import { FaEye, FaEyeSlash } from 'react-icons/fa'; // Import Eye Icons

function ScreenSignup() {
    const [fullName, setFullName] = useState("");
    const [PhoneNumber, setPhoneNumber] = useState("");
    const [Password, setPassword] = useState("");
    const [ConfirmPassword, setConfirmPassword] = useState("");
    const [Otp, setOtp] = useState("");
    const [isOtpSent, setIsOtpSent] = useState(false);
    const [isOtpVerified, setIsOtpVerified] = useState(false);
    const [isPasswordVisible, setIsPasswordVisible] = useState(false); // State for Password Visibility
    const [isConfirmPasswordVisible, setIsConfirmPasswordVisible] = useState(false); // State for Confirm Password Visibility
    const navigate = useNavigate();

    useEffect(() => {
        const handlePopState = () => {
            navigate("/", { replace: true });
        };
        window.history.pushState(null, null, '/');
        window.addEventListener("popstate", handlePopState);

        return () => {
            window.removeEventListener("popstate", handlePopState);
        };
    }, [navigate]);

    const handleSendOTP = async (e) => {
        e.preventDefault();
        try {
            const otpresponse = await axios.post('https://vendor.payzopartner.in/payzo-api/vendorSendOTP', {
                'phone_number': PhoneNumber,
            });
            if (otpresponse.data.success === true) {
                toast.success("OTP Sent Successfully");
                setIsOtpSent(true);
            } else {
                toast.error("Failed to Send OTP");
            }
        } catch (error) {
            console.log(error);
        }
    };

    const handleVerifyOTP = async (e) => {
        e.preventDefault();
        try {
            const verifyOTPeresponse = await axios.post('https://vendor.payzopartner.in/payzo-api/verifyVendorOTP', {
                'phone_number': PhoneNumber,
                'otp': Otp,
            });
            if (verifyOTPeresponse.data.success === true) {
                toast.success("OTP verified Successfully");
                setIsOtpVerified(true);  
            } else {
                toast.error("Failed to Verify OTP");
            }
        } catch (error) {
            console.log(error);
        }
    };

    const handleRegisterUser = async () => {
        if (Password !== ConfirmPassword) {
            toast.error("Passwords do not match!");
            return;
        }

        try {
            const registerresponse = await axios.post('https://vendor.payzopartner.in/payzo-api/vendorsignup', {
                'full_name': fullName,
                'phone_number': PhoneNumber,
                'password': Password,
                'confirm_password': ConfirmPassword,
            });
            if (registerresponse.data.success === true) {
                toast.success("Registration Successful");
                setTimeout(() => {
                    navigate('/screen-login');
                }, 3000);
            }
        } catch (error) {
            toast.error("Please do OTP verification")
        }
    };

    const handleOTP = (e) => {
        const value = e.target.value;
        if (/^\d{0,10}$/.test(value)) {  
            setPhoneNumber(e.target.value)
        }
    };

    const handleVeridyOTP = (e) => {
        const value = e.target.value;
        if (/^\d{0,6}$/.test(value)) {  
            setOtp(e.target.value)
        }
    };

    return (
        <div>
            <ToastContainer />
            <div className="screensignup-main-container">
                <div className="screensignup-card">
                    <h2 style={{ fontWeight: "700" }}>VENDOR SIGNUP</h2>
                    <div className="title-containers">
                        <strong style={{ color: "purple" }}>Vendor Full Name</strong>
                        <input
                            className="normal-input"
                            type="text"
                            maxLength={16}
                            placeholder="Enter Vendor Full Name"
                            value={fullName}
                            onChange={(e) => setFullName(e.target.value)}
                        />
                    </div>
                    <div className="title-containersB">
                        <strong style={{ color: "purple" }}>Phone Number</strong>
                        <div className="otp-level">
                            <input
                                className="special-input"
                                value={PhoneNumber}
                                onChange={handleOTP}
                                type="text"
                                placeholder="Enter Phone Number"
                            />
                            <button className="sendotp" onClick={handleSendOTP} disabled={isOtpSent}>
                                SEND OTP
                            </button>
                        </div>
                    </div>
                    <div className="title-containersB">
                        <strong style={{ color: "purple" }}>Verify OTP</strong>
                        <div className="otp-level">
                            <input
                                className="special-input"
                                value={Otp}
                                onChange={handleVeridyOTP}
                                type="text"
                                placeholder="Enter OTP"
                            />
                            <button className="sendotp" onClick={handleVerifyOTP}>
                                VERIFY OTP
                            </button>
                        </div>
                    </div>

                    {/* Show password fields only after OTP verification */}
                    {isOtpVerified && (
                        <>
                            <div className="title-containers">
                                <strong style={{ color: "purple" }}>Password</strong>
                                <div className="password-input">
                                    <input
                                        className="normal-input"
                                        type={isPasswordVisible ? "text" : "password"}
                                        placeholder="Enter Password"
                                        value={Password}
                                        onChange={(e) => setPassword(e.target.value)}
                                    />
                                    <span
                                        className="eye-icon"
                                        onClick={() => setIsPasswordVisible(!isPasswordVisible)}
                                    >
                                        {isPasswordVisible ? <FaEyeSlash /> : <FaEye />}
                                    </span>
                                </div>
                            </div>
                            <div className="title-containers">
                                <strong style={{ color: "purple" }}>Confirm Password</strong>
                                <div className="password-input">
                                    <input
                                        className="normal-input"
                                        type={isConfirmPasswordVisible ? "text" : "password"}
                                        placeholder="Confirm Password"
                                        value={ConfirmPassword}
                                        onChange={(e) => setConfirmPassword(e.target.value)}
                                    />
                                    <span
                                        className="eye-icon"
                                        onClick={() => setIsConfirmPasswordVisible(!isConfirmPasswordVisible)}
                                    >
                                        {isConfirmPasswordVisible ? <FaEyeSlash /> : <FaEye />}
                                    </span>
                                </div>
                            </div>
                        </>
                    )}
                    <button className="register-button" onClick={handleRegisterUser}>
                        REGISTER
                    </button>
                </div>
            </div>
        </div>
    );
}

export default ScreenSignup;
