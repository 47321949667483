import React, { useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { Offcanvas, Button } from "react-bootstrap";
import { AiOutlineMenuFold } from "react-icons/ai";
import { FaUserLarge } from "react-icons/fa6";
import { FaQuestion } from "react-icons/fa6";
import { FaWhatsapp } from "react-icons/fa";
import { IoKeyOutline } from "react-icons/io5";
import { useNavigate } from "react-router-dom";
import { FaBusinessTime } from "react-icons/fa6";
import { useLocation } from "react-router-dom";

function Offcanvass() {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const navigate = useNavigate();
  const location = useLocation();
  const VendorId = location.state?.VendorId;
  const BusinessId = location.state?.businessId;
  const userName = location.state?.userName;

console.log(VendorId + "Vendor Id Taken by Off Canvas");


  return (
    <div>
      <div>
        <Button
          style={{
            width: "3rem",
            height: "2rem",
            display: "flex",
            justifyContent: "center",
            alignItems: "center"
          }}
          variant="info"
          onClick={handleShow}
        >
          <p
            style={{ color: "white", fontSize: "1.2rem", paddingTop: ".5rem" }}
          >
            {" "}
            <AiOutlineMenuFold />
          </p>
        </Button>

        <Offcanvas show={show} onHide={handleClose}>
          <Offcanvas.Header closeButton>
            <Offcanvas.Title>Welcome Vendor</Offcanvas.Title>
          </Offcanvas.Header>
          <Offcanvas.Body>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                gap: "1rem"
              }}
            >
              <div
                onClick={() =>
                  navigate("/vendor-profile", { state: { VendorId} })
                }
                style={{
                  width: "20rem",
                  height: "2rem",
                  display: "flex",
                  flexDirection: "row",
                  gap: "1rem",
                  fontFamily: "sans-serif",
                  paddingLeft:'1rem',
                  cursor: "pointer",
                  backgroundColor: "white",
                  boxShadow: "1px 1px 8px lightgray",
                  fontWeight: "bold"
                }}
              >
                <p>
                  <FaBusinessTime />
                </p>
                <p style={{ marginTop: ".1rem" }}> Create Business</p>
              </div>
              <div
                onClick={() =>
                  navigate("/my-business", { state: { VendorId, BusinessId, userName } })
                }
                style={{
                  width: "20rem",
                  height: "2rem",
                  display: "flex",
                  flexDirection: "row",
                  gap: "1rem",
                  fontFamily: "sans-serif",
                  paddingLeft:'1rem',
                  cursor: "pointer",
                  backgroundColor: "white",
                  boxShadow: "1px 1px 8px lightgray",
                  fontWeight: "bold"
                }}
              >
                <p>
                  <FaUserLarge />
                </p>
                <p style={{ marginTop: ".1rem" }}>My Business</p>
              </div>

              {/* <div
                    style={{
                      width: "20rem",
                      height: "2rem",
                      display: "flex",
                      flexDirection: "row",
                      gap: "1rem",
                      fontFamily: "sans-serif",
                      paddingLeft:'1rem',
                      cursor: "pointer",
                      backgroundColor: "white",
                      boxShadow: "1px 1px 8px lightgray",
                      fontWeight: "bold"
                    }}
              >
                <p>
                  <IoKeyOutline />
                </p>
                <span
                  onClick={() =>
                    navigate("/change-password", { state: { userId } })
                  }
                >
                  Change Password
                </span>
              </div> */}

              <div
                  style={{
                    width: "20rem",
                    height: "2rem",
                    display: "flex",
                    flexDirection: "row",
                    gap: "1rem",
                    fontFamily: "sans-serif",
                    paddingLeft:'1rem',
                    cursor: "pointer",
                    backgroundColor: "white",
                    boxShadow: "1px 1px 8px lightgray",
                    fontWeight: "bold"
                  }}
              >
                <p>
                  <FaWhatsapp />
                </p>
                <a style={{color:'black',textDecoration:'none'}} href="tel: +91 9090070769">Whatsapp Support</a>
              </div>
            

              <div
                  style={{
                    width: "20rem",
                    height: "2rem",
                    display: "flex",
                    flexDirection: "row",
                    gap: "1rem",
                    fontFamily: "sans-serif",
                    paddingLeft:'1rem',
                    cursor: "pointer",
                    backgroundColor: "white",
                    boxShadow: "1px 1px 8px lightgray",
                    fontWeight: "bold"
                  }}
              >
                <p>
                  <FaQuestion />
                </p>
                <span onClick={() => navigate("/")}>Website</span>
              </div>
            </div>
          </Offcanvas.Body>
        </Offcanvas>
      </div>
    </div>
  );
}

export default Offcanvass;
