import React, { useState } from "react";
import "../styles/feedback.scss";
import Centerfeedback from "../old_images/logo.png";
import { Input } from "antd";

function FeedBack() {
  const { TextArea } = Input;
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [mobile, setMobile] = useState("");
  const [issue, setIssue] = useState("");
  const [termsAccepted, setTermsAccepted] = useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();
    // Handle form submission logic here
    console.log({
      name,
      email,
      mobile,
      issue,
      termsAccepted
    });
  };

  return (
    <div className="feedback-container">
      <div className="feedback-forms">
        <div className="form-image-handler">
          <img className="form-logo" src={Centerfeedback} alt="" />
        </div>
        <div className="former">
          <form onSubmit={handleSubmit}>
            <input
              className="in"
              type="text"
              placeholder="Enter your name"
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
            <input
              className="in"
              type="text"
              placeholder="Enter your Email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
            <input
              className="in"
              type="number"
              placeholder="Enter your mobile number"
              value={mobile}
              onChange={(e) => setMobile(e.target.value)}
            />
            <TextArea
              placeholder="Please write your issue our team will contact you soon...."
              maxLength={300}
              value={issue}
              onChange={(e) => setIssue(e.target.value)}
            />
            <div>
              <span>
                <input
                  type="checkbox"
                  checked={termsAccepted}
                  onChange={(e) => setTermsAccepted(e.target.checked)}
                />
              </span>
              <span> </span>
              <span>I accept Terms & Conditions</span>
            </div>
            <button className="submit" type="submit" onClick={handleSubmit}>
              Submit
            </button>
          </form>
        </div>
        <div className="revok">
          <p
            style={{
              color: "orange",
              fontSize: "1.2rem",
              fontFamily: "roboto"
            }}
          >
            Bissi
          </p>
          <p></p>
          <p style={{ fontFamily: "roboto", fontSize: "1.2rem" }}>Yaazi</p>
          <p
            style={{ color: "green", fontSize: "1.2rem", fontFamily: "roboto" }}
          >
            Technologies Pvt. Ltd
          </p>
        </div>
      </div>
    </div>
  );
}

export default FeedBack;
