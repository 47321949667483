import React, { useState } from "react";
import "../vendor_screens/vendor_style.scss";
import { FaKey } from "react-icons/fa6";
import axios from "axios";
import { useLocation, useNavigate } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";

function VendorChangePassword() {
  const location = useLocation();
  const navigate = useNavigate();
  const userId = location.state?.userId;
  console.log(userId + "change Pass");

  const [newPassword, setNewPassword] = useState("");
  const [confirmNewPassword, setConfirmNewPassword] = useState("");

  const handleChangePassword = async () => {
    // Basic validation
    if (!newPassword || !confirmNewPassword) {
      toast.error("Please fill in all fields");
      return;
    }

    if (newPassword !== confirmNewPassword) {
      toast.error("New password and confirm password do not match");
      return;
    }

    try {
      const response = await axios.put(
        `https://api.payzopartner.com/payzo-api/reset-password/${userId}`,
        {
          user_id: userId,
          nPassword: newPassword,
          cPassword: confirmNewPassword
        }
      );

      if (response.data.success) {
        toast.success("Password changed successfully");
        navigate("/screen-login");
      } else {
        toast.error(response.data.message || "Failed to change password");
      }
    } catch (error) {
      console.error("Password change failed", error);
      toast.error("Something went wrong. Please try again.");
    }
  };

  return (
    <div className="change-password-main-container">
      <ToastContainer />
      <div className="password-change-card">
        <div className="changepassword-title">
          <p style={{ fontSize: "1.5rem" }}>
            <FaKey />
          </p>
          <h3>Change Password</h3>
        </div>
        <div className="change-password-form">
          {/* <div className='pass-inps'>
                        <p>Old Password</p>
                        <input
                            type='password'
                            placeholder='Enter Old Password'
                            value={oldPassword}
                            onChange={(e) => setOldPassword(e.target.value)}
                        />
                    </div> */}
          <div className="pass-inps">
            <p>New Password</p>
            <input
              type="password"
              placeholder="Enter New Password"
              value={newPassword}
              onChange={(e) => setNewPassword(e.target.value)}
            />
          </div>
          <div className="pass-inps">
            <p>Confirm New Password</p>
            <input
              type="password"
              placeholder="Confirm New Password"
              value={confirmNewPassword}
              onChange={(e) => setConfirmNewPassword(e.target.value)}
            />
          </div>
          <ul>
            <li>Be 1-12 Characters</li>
            <li>Have at least one Lower and Upper Case Letter</li>
            <li>Have at least One Digit</li>
            <li>Have at least One Special Character</li>
            <li>Not Contain Repeated Letters</li>
          </ul>
          <div className="change-pass-btn-container">
            <button className="change-pass-btn" onClick={handleChangePassword}>
              Change Password
            </button>
            <button
              className="change-pass-cancel"
              onClick={() => navigate("/my-business", { state: { userId } })}
            >
              Cancel
            </button>
          
          </div>
        </div>
      </div>
    </div>
  );
}

export default VendorChangePassword;
