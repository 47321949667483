import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import Dropdown from "react-bootstrap/Dropdown";
import { FaUserLarge } from "react-icons/fa6";
import "react-toastify/dist/ReactToastify.css";

function DropDown() {
  const navigate = useNavigate();

  // // Block back navigation by pushing a new state
  // useEffect(() => {
  //   // Push a new state to prevent going back to the previous page
  //   window.history.pushState(null, null, window.location.href);

  //   // Define the handler to block the back navigation
  //   const handlePopState = () => {
  //     // Force a redirect to prevent going back to the profile page
  //     navigate("/vendor-profile", { replace: true });
  //   };

  //   // Add event listener for the popstate event to handle back button clicks
  //   window.addEventListener("popstate", handlePopState);

  //   // Continuously push the current state every time the user navigates away
  //   const intervalId = setInterval(() => {
  //     window.history.pushState(null, null, '/');
  //   }, 100);

  //   // Cleanup the event listener and interval on component unmount
  //   return () => {
  //     clearInterval(intervalId);
  //     window.removeEventListener("popstate", handlePopState);
  //   };
  // }, [navigate]);

  // // Handle logout (navigate to vendor-login page and block back button)
  // const handleLogouthandler = (e) => {
  //   e.preventDefault();

  //   // Show toast for logout
  //   toast.success("Logged out successfully!");

  //   // Redirect to login page (vendor-login)
  //   navigate("/vendor-login", { replace: true });

  //   // Block back navigation after logout by pushing a new state
  //   window.history.pushState(null, null, window.location.href);
  // };



  const handleLogut = (e)=> {
    e.preventDefault();
    window.localStorage.removeItem("PAYZOWEBSITEENCRYPTION");
    navigate("/screen-login")
  }

  return (
    <div>
      <ToastContainer position="top-right" autoClose={5000} hideProgressBar={false} newestOnTop={true} />
      <Dropdown>
        <Dropdown.Toggle
          variant="info"
          id="dropdown-basic"
          style={{
            color: "white",
            borderRadius: "50%",
            height:"3rem",
            width:"3rem",
            marginBottom:"1rem"
          }}
        >
          <FaUserLarge />
        </Dropdown.Toggle>

        <Dropdown.Menu>
          <Dropdown.Item onClick={() => navigate("/")}>Go to Website</Dropdown.Item>
          <Dropdown.Item onClick={handleLogut}>Log out</Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
    </div>
  );
}

export default DropDown;
