import React from "react";
import { Table } from "antd";

const columns = [
  { title: "Sr.No", dataIndex: "name" },
  Table.EXPAND_COLUMN,
  { title: "Source of Information", dataIndex: "age", key: "age" },
  Table.SELECTION_COLUMN,
  {
    title: "Nature of Information Collected",
    dataIndex: "address",
    key: "address"
  }
];

const data = [
  {
    key: 1,
    name: "1",
    age: " Users/Customers",
    address:
      "Personal Identifying information Such as Name, Adress and Phone numbers Email id Age , Personal Description , Profile Photograph etc, payment  Information, Device Information(if you Provided), Ip Adress , Content of Reviews and Email us Voice recordings when you call to us Credit usage, login detail, device log files, while using Platform"
  },
  {
    key: 2,
    name: "2",
    age: "Vendors / Advertisers",
    address:
      "Personal Identifying information such as name, address and phone numbers; email Id, Age, personal description, profile photograph ,business details etc.,.payment information location information Device information (if you provided) IP address. content of reviews and e-mails to us content of reviews and e-mails to us images and other content collected or stored in connection with our Services information and officially valid documents (KYC regarding identity and address information, including mobile & landline number, place of business, valid Email id, vendor’s photo, id & address proof (such as Aadhar card, Pan Card, GST, Voter Id Card, Passport, Shop and Establishment Certificate, etc.,.) credit usage Device log files and configurations etc.,.  "
  },
  {
    key: 3,
    name: "3",
    age: "Automatic Information",
    address:
      "•	IP address of your device connected to our platform 	Login details, e-mail address, and password, device log files etc. location of device/computer. 	content interaction information, downloads, network details etc 	device metrics, application usage, connectivity data, and any errors or event failures 	our Services metrics, any technical errors, interactions with our service features and content, settings preferences and backup information, location of device, file name, dates, times etc while using our service  	content use history 	URLs including date & time; products & contents viewed or searched for; page response times, download errors, length of visits to certain pages, and page interaction information etc. 	phone numbers used to call to us 	Images while visiting our platforms 	device identifiers, cookies, browsing history, usage history, and/or other technical "
  },
  {
    key: 4,
    name: "4",
    age: "Third Party Information",
    address:
      " Corporate & financial information about our co-branded partners, and other third party associated with us  	CIN Number, PAN Number, GSTN Number etc 	Location information 	Device information (if you provided)  	IP address  	Internet-connected devices details 	Identity and address information etc"
  }
];

function Termtable() {
  return (
    <div>
      <Table
        columns={columns}
        rowSelection={{}}
        expandable={{
          expandedRowRender: (record) => (
            <p style={{ margin: 0 }}>{record.description}</p>
          )
        }}
        dataSource={data}
      />
    </div>
  );
}

export default Termtable;
