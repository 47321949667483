import React, { useState } from "react";
import { Card } from "react-bootstrap";
import "../src/styles/blogstyle.scss";

const ClickableCard = ({ imageUrl, cardTitle, cardText }) => {
  const [isClicked, setIsClicked] = useState(false);

  const handleCardClick = () => setIsClicked(true);
  const handleOverlayClick = () => setIsClicked(false);

  return (
    <>
      <Card
        className="clicked-card"
        onClick={handleCardClick}
        style={{ width: "18rem", cursor: "pointer" }}
      >
        <Card.Img variant="top" src={imageUrl} />
        <Card.Body>
          <Card.Title>{cardTitle}</Card.Title>
          <Card.Text>{cardText}</Card.Text>
        </Card.Body>
      </Card>

      {isClicked && (
        <div className="fullscreen-overlay" onClick={handleOverlayClick}>
          <img src={imageUrl} alt="Fullscreen" className="fullscreen-image" />
        </div>
      )}
    </>
  );
};

export default ClickableCard;
