import React, { Component } from "react";
import "../styles/privacy.scss";

class Tables extends Component {
  render() {
    let heading = [
      "S.no",
      "Source of Information",
      "Nature of information Collected"
    ];
    let body = [
      [
        1,
        "Users/Customers",
        "•	Personal Identifying information such as name, address and phone numbers; email Id, Age, personal description, profile photograph ,payment information, Business details, education details,Occupation details etc.,. Location information Device information Ip adress content of reviews and email voice recordings when you call to us credit usage, login detail, device log files etc "
      ],
      [
        2,
        "Vendors/Advitisors",
        "Personal identifying information such as name, adress, and phone number , email id, Age, personal description, profile photograph , business details etc , Payment information Location information Device information Ip Address , Content of review and email-to us Voice recordings when you call to us images and other Content Collected or stored in Connection with our services information and officially Valid documents (KYC regarding identity and adress information , including mobile and landline number , Place of business, Vlid Email id , Vendors photo, id & Adress proof such as Aadhar, Pan Card, Gst voter id, Passport , Shop and Establishment Certificate etc.. Credit usage Device log files and Configuration etc"
      ],
      [
        3,
        "Automatic Information",
        "IP adress of your device Connected to your platform Logindetails, email-adress and password , device log files etc Location of device or local computer Content interaction information download network details etc .. Device metrics, applications connective data, any error or event failures Our services metrics any technical error , interaction with our service feauters and content  setting prefernces and backup information, location of device, filename, dates times, times etc while using our service Content use history URL including date & time products & content viewed or scratched for page response times, download errors , length of visit to certain page and page interaction information etc phone numbers used to cal us Device identifiers Cookies browsing history, usage history, and other technical information"
      ],
      [
        4,
        "Third-party information",
        "corporate & financial information about our co-branded partners and other third party associated with us CIN Number, PAN Number, GSTN Number etc... Location information Device information(if your provided) IP Adress Internet Connected device details identify and adress information etc"
      ]
    ];
    return (
      <div className="table-main">
        <Table className="t-bodys" heading={heading} body={body} />,
      </div>
    );
  }
}

class Table extends Component {
  render() {
    let heading = this.props.heading;
    let body = this.props.body;
    return (
      <table style={{ width: 1300, height: 700, border: "1px solid" }}>
        <thead style={{ backgroundColor: "skyblue", textAlign: "center" }}>
          <tr style={{ border: "1px solid" }}>
            {heading.map((head, headID) => (
              <th key={headID}>{head}</th>
            ))}
          </tr>
        </thead>
        <tbody style={{ fontFamily: "roboto", textAlign: "center" }}>
          {body.map((rowContent, rowID) => (
            <TableRow rowContent={rowContent} key={rowID} />
          ))}
        </tbody>
      </table>
    );
  }
}

class TableRow extends Component {
  render() {
    let row = this.props.rowContent;
    return (
      <tr>
        {row.map((val, rowID) => (
          <td key={rowID}>{val}</td>
        ))}
      </tr>
    );
  }
}

export default Tables;
