import React from "react";


function  Offercards (){
 return(
    <div>
        <h1>wELCOME TO OFFER CARD PAGE</h1>
    </div>
 )
}

export default Offercards;