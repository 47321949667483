import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom'; // Ensure this is imported
import "../src/styles/newdashboard.scss";
import { FaWallet, FaGooglePlay, FaFacebook, FaLinkedin } from "react-icons/fa6";
import { BiSolidOffer } from "react-icons/bi";
import { SiInstagram } from "react-icons/si";
import { BsTwitterX, BsYoutube } from "react-icons/bs";
import PayzoLogo from "../src/images/zo.png";
// import CarouselSlice from './carousel_card-slip';
// Import other images here...
import { Spinner } from "react-bootstrap";
import Popup from "./accessories/popup"; 
import OffersIcon from "../src/images/offers.jpg";
import BusinessIcon from "../src/images/stores.jpg";
import userIcon from "../src/images/users.jpg";
import Pyaments from "../src/images/payment.jpg";

//carousel-icons imported
import Conclave from "../src/images/Conclave.PNG";
import BusinessStores from "../src/images/store.png";
import DoorKnock from "../src/images/door_knock.PNG";
import Dthrecharge from "../src/images/tv.PNG";
import Freepost from "../src/images/golden.PNG";
import MobileRecharge from "../src/images/mobile.PNG";
import Offlineoffers from "../src/images/offoffers.PNG";
import OnlineOffers from "../src/images/onlineoffers.PNG";
import SpinWin from "../src/images/spinandwin.PNG";
import Wallet from "../src/images/wallet.png";

function NewDashboard() {
    const [loading, setLoading] = useState(true);
    const navigate = useNavigate();
    
    useEffect(() => {
        document.title = "Payzo"; 
        fetchData();
        setTimeout(() => {
            setLoading(false);
        }, 2000);
    }, []);

    const fetchData = async () => {
        // Fetch your data here if necessary
    };


//       useEffect(() => {
//     // Push a new state to prevent going back to the previous page
 

//     // Define the handler to block the back navigation
//     const handlePopState = () => {
//       // Force a redirect to prevent going back to the profile page
//       navigate("/", { replace: true });
//     };

//     // Add event listener for the popstate event to handle back button clicks
//     window.addEventListener("popstate", handlePopState);

//     // Continuously push the current state every time the user navigates away
//     const intervalId = 
//       window.history.pushState(null,null, '/');
     

//     // Cleanup the event listener and interval on component unmount
//     return () => {
//       clearInterval(intervalId);
//       window.removeEventListener("popstate", handlePopState);
//     };
//   }, [navigate]);


useEffect(()=>{

        const handlePopState = () => {
      // Force a redirect to prevent going back to the profile page
      navigate("/", { replace: true });
    };
   //     // Continuously push the current state every time the user navigates away
    const intervalId = 
      window.history.pushState(null,null, '/');
     

    // Cleanup the event listener and interval on component unmount
    return () => {
      clearInterval(intervalId);
      window.removeEventListener("popstate", handlePopState);
    };
})



    return (
        <div>
            {/* {loading ? (
                <div className="d-flex justify-content-center align-items-center" style={{ height: "100vh" }}>
                    <Spinner variant="primary" animation="border" role="status" />
                    <span style={{ marginLeft: "1rem" }} className="sr-only">Welcom to Payzo Partner....</span>
                </div>
            ) : ( */}
                <div className='mains'>
                    <div className="main-container-section">
                        <div className="main-navtools">
                            {/* <img className="main-navtool-logo" src={PayzoLogo} alt="Payzo Logo" /> */}
                            <div className="aerobic">
                                <p onClick={()=> navigate('/')}>Home</p>
                                <p onClick={()=> navigate('/services')}>Payzo-services</p>
                                <p onClick={()=> navigate('/blog-card')}>Blogs</p>
                               {/* <p onClick={()=> navigate('/screen-one')}>Vendor Login</p> */}
                               <a style={{color:'white', marginBottom: '1rem', textDecoration:'none'}} href='https://payzopartner.in/screen-one'>Vendor Login</a>
                            </div>
                        </div>
                        <div className="main-navtool-body">
                            <div className='orbit-1'>
                                <p className='mb-stop'>
                                We are the ultimate destination
                                     for cash back on every Payment
                                     through our scanner! 
                                </p>
                                <div className='mb-stop' style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', gap: '1rem' }}>
                                        <p className='mb-stop'>Download Now on PlayStore</p>
                                        <p className='mb-stop'><Popup/></p>
                                    </div>
                                <div className='duples'>
                                    <p className='tit-font'>We are the ultimate destination
                                     for cash back on every Payment
                                     through our scanner!
                                     </p>
                                    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', gap: '1rem' }}>
                                        <p className='down-font'>Download Now on PlayStore</p>
                                        <p ><Popup/></p>
                                    </div>
                                </div>
                            </div>
                            {/* Uncomment this line if you want to use CarouselSlice */}
                            {/* <div className='orbit-2'><CarouselSlice/></div> */}
                        </div>
                    </div>
{/* 
                    <marquee className="marq" bgcolor="black" direction="left" loop="3">
                        <div style={{ color: "#ffff", fontWeight: "bold" }} className="geek1">
                            Welcome to Payzo, Offers * Deals * Discounts * Coupons * Rewards * Scratch cards........
                        </div>
                    </marquee> */}

                    <h1 className='bar' style={{ color: 'black', width:'90%', marginTop:"6rem", justifyContent:'center', alignItems:'center', display:'flex' }}>Let's talk Numbers !</h1>
                    <div className="Business-cards-container">
                        {/* {[
                            { title: "Users", count: "10000+", imgSrc:{OffersIcon}  },
                            { title: "Business Stores", count: "120+", imgSrc: "https://t3.ftcdn.net/jpg/01/27/54/10/360_F_127541046_yLlMu84gM9kKBjzNKp6ZSwAjuydyJA48.jpg" },
                            { title: "Offers", count: "150+", imgSrc: "https://as1.ftcdn.net/v2/jpg/02/50/93/60/1000_F_250936053_xQ3IBXaplXyTErPwnPzu80xlIRyUeYXR.jpg" },
                            {title :"Payments", count : "0"}
                        ].map((card, index) => (
                            <div className="card-1" key={index}>
                                <img className="user-dew" src={card.imgSrc} alt={card.title} />
                                <h2 className='storer'>{card.title}</h2>
                                <p style={{ color: "purple", fontWeight: "700" }}>{card.count}</p>
                            </div>
                        ))} */}
                        <div className="card-1" >
                                <img className="user-dew" src={userIcon} alt="offer-icon" />
                                <h2 className='storer'>Users</h2>
                                <p style={{ color: "purple", fontWeight: "700" }}>10K+</p>
                            </div>
                            <div className="card-1" >
                                <img className="user-dew" src={BusinessIcon} alt="offer-icon" />
                                <h2 className='storer'>Stores</h2>
                                <p style={{ color: "purple", fontWeight: "700" }}>55+</p>
                            </div>
                            <div className="card-1" >
                                <img className="user-dew" src={OffersIcon} alt="offer-icon" />
                                <h2 className='storer'>Offers</h2>
                                <p style={{ color: "purple", fontWeight: "700" }}>45+</p>
                            </div>
                            <div className="card-1" >
                                <img className="user-dew" src={Pyaments} alt="offer-icon" />
                                <h2 className='storer'>Payments</h2>
                                <p style={{ color: "purple", fontWeight: "700" }}>0</p>
                            </div>

                    </div>

                    <div className="body-main-container-dashboard">
                        {["Payzo-Ads", "Payzo-Offers"].map((title, index) => (
                            <div className="sidecard-one" key={index}>
                                <h1 style={{ color: "black" }} className="duet">{title}</h1>
                                <p className="duet">It's a cutting-edge online advertising feature developed by Payzo partner. Advertisers can seamlessly present concise yet impactful ads and service offerings to smartphone and web users.</p>
                                <div className='cover' >
                                    <div className='circle'>
                                        {title.includes("Ads") ? <FaWallet /> : <BiSolidOffer />}
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>

                    <h2 className='pnp' style={{ marginLeft: "3rem", color: "black" }}>PAYZO Feauters</h2>
                    <div className='card-slide-carousel'>
                        <div className='turner'>
                            {[
                                { imgSrc: BusinessStores, title: "Business Store" },
                                { imgSrc: DoorKnock, title: "Door Knock Ads" },
                                { imgSrc: Dthrecharge, title: "DTH Recharge" },
                                { imgSrc: Freepost, title: "Golden Hour Deals" },
                                { imgSrc: MobileRecharge, title: "Mobile Recharge" },
                                { imgSrc: Offlineoffers, title: "Offline Offers" },
                                { imgSrc: OnlineOffers, title: "Online Offers" },
                                { imgSrc: SpinWin, title: "Spin & Win" },
                                { imgSrc: Wallet, title: "Wallets" },
                                { imgSrc: Conclave, title: "Conclaves" },
                            ].map((item, index) => (
                                <div className="flip-card" key={index}>
                                    <div className="flip-card-inner">
                                        <div className="flip-card-front">
                                            <img style={{ width: '6rem', height: '6rem' }} src={item.imgSrc} alt={item.title} />
                                        </div>
                                        <div className="flip-card-back">
                                            <p>{item.title}</p>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>

                    <div className='customer-revew-section-container'>
                        <div className='follow-us'>
                            <h2>Follow us for Updates</h2>
                            <p>Payzo offers a diverse range of coupons, offers, discounts, deals, rewards and scratch cards on a daily basis. It also encompasses many other features.</p>
                            <div className='social'>
                                <a  style={{color:'black'}} href='https://www.instagram.com/payzopartner_official?igsh=OTloNmhkbTBmZTBi'><SiInstagram /></a>
                                <a  style={{color:'black'}} href='https://x.com/payzo_offl?t=AvN7eZs6ulc-NL6aR5_j3A&s=09'><BsTwitterX /></a>
                                <a style={{color:'black'}} href='https://youtube.com/@payzo_official?si=2ImXZ5IOyPKmPBKB'><BsYoutube /></a>
                                <a style={{color:'black'}} href='https://www.facebook.com/profile.php?id=61554188187812'><FaFacebook /></a>
                                <a style={{color:'black'}} href='https://www.linkedin.com/company/bissiyaazi/'><FaLinkedin /></a>
                            </div>
                        </div>
                    </div>

                    <div className='footer-container-main'>
                        <div className='font'>
                            <h2>Get in Touch</h2>
                            </div>
                        <div className='footer-section-one'>
                            <div className='comapany-details'>
                               
                                <h3>Customer Support</h3>
                                <a href='tel: 9898070769'>Phone: 9898070769</a>
                                <a href='mailto: support@payzopartner.com'>Email: support@payzopartner.com</a>
                                <p>24/7 Customer Support</p>
                            </div>
                            <div className='quicklink-container'>
                                <h3>Sales and Business</h3>
                                <p>Email: hello@payzopartner.com</p>
                                <strong style={{color:"black"}}>Careers</strong>
                                <p>hr@payzopartner.com</p>
                            </div>
                            <div className='quicklink-containers'>
                                <h3>Registered Address</h3>
                                <p>Ravi Nagar, Visakhapatnam, Andhra Pradesh </p>
                                <strong style={{color:"black"}}>Corporate Address:</strong>
                                <p>19-518, Plot-78, BT Layout, Road no-4, Dharapalem, Andhra Pradesh, Visakhapatnam-40</p>
                            </div>
                            <div className='quicklink-container'>
                                <h3>Quick Links</h3>
                                <p onClick={()=> navigate('/terms-conditions')}>Terms & Conditions</p>
                                <p onClick={()=> navigate('/privacy-policy')}>Privacy Policy</p>
                                <p onClick={()=> navigate('/cancellation-policy')}>Refund Policy</p>
                                <p onClick={()=> navigate('/delete-account')}>Delete Account</p>
                            </div>
                        </div>

                        <div className='section-two'>
                            <div className='dms' style={{ width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', gap: '1rem' }}>
                                <p className='lbp'>------------------------------------------------</p>
                                <h6 className='last-title'>BISSI YAAZI TECHNOLOGIES PVT.LTD</h6>
                                <p className='lbp'>------------------------------------------------</p>
                            </div>
                            <div>
                                <p>@2024 Copyright: Payzopartner.com</p>
                            </div>
                        </div>
                    </div>
                </div>
            {/* )} */}
        </div>
    );
}

export default NewDashboard;
