import React from "react";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Logo from "../old_images/logo.png"
import "../styles/blogstyle.scss";
import AdpostBlog from "../old_images/blogs/adpost.jpg";
import BusinessNetwork from "../old_images/blogs/Business_network.jpg";
import BusinessStoreBlogs from "../old_images/blogs/Businesstores.jpg";
import ConclaveBlogs from "../old_images/blogs/conclave.jpg";
import Doorknock from "../old_images/blogs/door_knock_ad.jpg";
import FeedbackformsBlog from "../old_images/blogs/feedbackform.jpg";
import FreepostBlog from "../old_images/blogs/freepost.jpg";
import GoldenhordealBlog from "../old_images/blogs/golden_hours_deals.jpg";
import OffercardBlogs from "../old_images/blogs/offercards.jpg";
import OfflineoffersBlogs from "../old_images/blogs/offlineoffers.jpg";
import Online_offersBlogs from "../old_images/blogs/onlineoffers.jpg";
import PayzoAtmBlogs from "../old_images/blogs/payzo-atm.jpg";
import WebreachBlogs from "../old_images/blogs/web-reach.jpg";
import WriteUpBlogs from "../old_images/blogs/write-ups.jpg";
import { Spinner } from "react-bootstrap";
import ClickableCard from "../hovercard";

function Blogcard() {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 1000);
  }, []);
  return (
    <div>
      {loading ? (
        <div
          className="d-flex justify-content-center align-items-center"
          style={{ height: "100vh" }}
        >
          <Spinner animation="border" role="status"></Spinner>
          <span className="sr-only">Loading Blogs...</span>
        </div>
      ) : (
        <div>
          <div className="blog-cont">
            <div>
              <img className="blog-page-logo" src={Logo} alt="" />
            </div>

            <div className="blog-header-guide">
              <p style={{ cursor: "pointer" }} onClick={() => navigate("/")}>
                Home
              </p>
              <p
                style={{ cursor: "pointer" }}
                onClick={() => navigate("/services")}
              >
                Services
              </p>
              <p
                style={{ cursor: "pointer" }}
                onClick={() => navigate("/blog-card")}
              >
                Blogs
              </p>
              <p
                style={{ cursor: "pointer" }}
                onClick={() => navigate("/feedback")}
              >
                Feedback
              </p>
            </div>
          </div>

          {/* Blog-card */}

          <div className="blog-ticker">
          <ClickableCard
              className="blog-routes"
              imageUrl={BusinessNetwork}
              cardTitle="Business Network"
            />

            <ClickableCard
              className="blog-routes"
              imageUrl={BusinessStoreBlogs}
              cardTitle="Business Stores"
            />

            <ClickableCard
              className="blog-routes"
              imageUrl={ConclaveBlogs}
              cardTitle="Conclaves"
            />

            <ClickableCard
              className="blog-routes"
              imageUrl={Doorknock}
              cardTitle="Door Knock Ads"
            />

            <ClickableCard
              className="blog-routes"
              imageUrl={GoldenhordealBlog}
              cardTitle="Golden Hour Deals"
            />

        

            <ClickableCard
              className="blog-routes"
              imageUrl={OfflineoffersBlogs}
              cardTitle="Offline offers"
            />

            <ClickableCard
              className="blog-routes"
              imageUrl={Online_offersBlogs}
              cardTitle="Online offers"
            />

            <ClickableCard
              className="blog-routes"
              imageUrl={PayzoAtmBlogs}
              cardTitle="Payzo ATM"
            />

            <ClickableCard
              className="blog-routes"
              imageUrl={WebreachBlogs}
              cardTitle="Web reach"
            />

            </div>

         
      
          </div>
        
      )}
    </div>
  );
}

export default Blogcard;
