import React, { useState } from 'react';
import '../accessories/popups.css';
import { FaGooglePlay } from 'react-icons/fa6';
import QrCode from "../images/qs.png"

function Popup() {
    const [showPopup, setShowPopup] = useState(false);
    const togglePopup = () => {
        setShowPopup(!showPopup);
    };

    const PopupContent = ({ closePopup }) => {
        return (
            <div className="popup">
                <div className="popup-inner">
                <p style={{fontSize:'1.3rem', marginTop:'0.8rem', color:'black',cursor:'pointer', marginLeft:'8rem',
                    marginBottom:'1rem'
                 }} onClick={closePopup}>X</p>
                    <img 
                        className='qr-image' 
                        src={QrCode}
                        alt='Download payZo App' 
                    />
                    <p>Scan QR Code</p>
                    <div className='vendor-btns'>
                        
                        <a href='https://play.google.com/store/apps/details?id=com.payzo.app'>Manual Download</a>
                    </div>
                </div>
            </div>
        );
    };

    return (
        <div>
            <button className='vendor-btn' onClick={togglePopup}><FaGooglePlay /></button>
            {showPopup && <PopupContent closePopup={togglePopup} />}
        </div>
    );
}

export default Popup;
