import React, { useEffect, useState } from "react";
import "../vendor_screens/vendor_style.scss";
import { useLocation } from "react-router-dom";
import VendorOfferPayzo from "../vendor_screens/vendor_images/vendorpayzo.png";
import { IoIosLogOut } from "react-icons/io";
import { useNavigate } from "react-router-dom";
import { Input } from "antd";
import { ToastContainer, toast } from "react-toastify";
import axios from "axios";
import "../vendor_offercards.scss";

function VendorOfferSelect() {
  const location = useLocation();
  const { state } = location;
  const businesstype = state?.businesstype;
  const VendorId = state?.VendorId;
  const businessId = state?.BusinessId;
  const businessType = state?.businesstype;


  const navigate = useNavigate();
  const { TextArea } = Input;
  
  const [activeOffer, setActiveOffer] = useState(null);
  const [imageFile, setImageFile] = useState(null);
  const [imageOfferStartDate, setImageOfferStartDate] = useState("");
  const [imageOfferEndDate, setImageOfferEndDate] = useState("");
  const [imageOfferStartTime, setImageOfferStartTime] = useState("");
  const [imageOfferEndTime, setImageOfferEndTime] = useState("");
  const [approvalStatusImageOffer, setApprovalStatusImageOffer] = useState("pending");
  const [remarkImageOffer, setRemarkImageOffer] = useState("hold");
  const [imageOfferTitle, setImageOfferTitle] = useState("Image Offer");
  const [imageOfferStatus, setImageofferStatus] = useState("Image");
  const [DescriptionofferStatus, setDescriptionofferStatus] = useState("Description");


  // Description offer initialization
  const [descriptionOfferTitle, setDescriptionOfferTitle] = useState("");
  const [offerDescription, setOfferDescription] = useState("");
  const [descriptionOfferStartDate, setDescriptionOfferStartDate] = useState("");
  const [descriptionOfferEndDate, setDescriptionOfferEndDate] = useState("");
  const [descriptionOfferStartTime, setDescriptionOfferStartTime] = useState("");
  const [descriptionOfferEndTime, setDescriptionOfferEndTime] = useState("");
  const [descriptionImageFile, setDescriptionImageFile] = useState(null);
  const [category, setCategory] = useState([]);

  const toggleOffer = (offerId) => {
    setActiveOffer(activeOffer === offerId ? null : offerId);
  };


  const handleLogout = (e)=> {
    e.preventDefault();
    window.localStorage.removeItem("PAYZOWEBSITEENCRYPTION");
    navigate("/screen-login")
  }

  useEffect(() => {
    const fetchBusinessForCategory = async () => {
      try {
        const response = await axios.get(`https://vendor.payzopartner.in/payzo-api/getBusinessById/${businessId}`);
        if (response.data.success) {
          const categoryId = response.data.business.category_id;
          setCategory([categoryId]); // Handling category as an array
        }
      } catch (error) {
        console.log(error);
      }
    };

    fetchBusinessForCategory();
  }, [businessId]);

  const handleImageOfferSubmit = async (e) => {
    e.preventDefault();
    if (!imageFile || !imageOfferStartDate || !imageOfferEndDate || !imageOfferStartTime || !imageOfferEndTime) {
      toast.error("Please fill all the fields.");
      return;
    }

    try {
      const formData = new FormData();
      formData.append('vendor_id', VendorId);
      formData.append('business_id', businessId);
      formData.append('start_date', imageOfferStartDate);
      formData.append('end_date', imageOfferEndDate);
      formData.append('start_time', imageOfferStartTime);
      formData.append('end_time', imageOfferEndTime);
      formData.append('offer_image', imageFile);
      formData.append('offer_type', businesstype);
      formData.append('approval_status', approvalStatusImageOffer);
      formData.append('remark', remarkImageOffer);
      formData.append('offer_title', imageOfferTitle);
      formData.append('business_category', category);
      formData.append('business_type', businessType);
      formData.append('offer_status',imageOfferStatus );
      const response = await axios.post('https://vendor.payzopartner.in/payzo-api/addImageOffer', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });

      if (response.data.success) {
        toast.success("Image offer created successfully!");
      } else {
        toast.error("Failed to create offer.");
      }
    } catch (error) {
      console.log(error);
      toast.error("An error occurred while submitting the offer.");
    }
  };

  const handleDescriptionOfferSubmit = async (e) => {
    e.preventDefault();
    if (!descriptionImageFile || !descriptionOfferStartDate || !descriptionOfferEndDate || !descriptionOfferStartTime || !descriptionOfferEndTime  || !offerDescription) {
      toast.error("Please fill all the fields.");
      return;
    }
 
    try {
      const formData = new FormData();
      formData.append('vendor_id', VendorId);
      formData.append('business_id', businessId);
      formData.append('offer_image', descriptionImageFile);
      formData.append('offer_type', businesstype);
      formData.append('offer_title', descriptionOfferTitle);
      formData.append('offer_description', offerDescription);
      formData.append('start_date', descriptionOfferStartDate);
      formData.append('end_date', descriptionOfferEndDate);
      formData.append('start_time', descriptionOfferStartTime);
      formData.append('end_time', descriptionOfferEndTime);
      formData.append('approval_status', approvalStatusImageOffer);
      formData.append('remark', remarkImageOffer);
      formData.append('business_category', category);
      formData.append('business_type', businessType);
      formData.append('offer_status', DescriptionofferStatus);
      const response = await axios.post('https://vendor.payzopartner.in/payzo-api/addDescriptionOffer', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });

      if (response.data.success) {
        toast.success("Description Offer Created Successfully");
      } else {
        toast.error("Failed to create Description Offer");
      }
    } catch (error) {
      console.log(error);
      toast.error("An error occurred while submitting the description offer.");
    }
  };


  
  return (
    <div className="vendor-offer-container-main">
      <ToastContainer />
      <div className="vendor-offer-navbar">
        <img className="vendor-offer-payzo-logo" src={VendorOfferPayzo} alt="Payzo Logo" />
        <div className="logout-matrix">
          {/* <p style={{ fontWeight: "bold", fontSize: "1.5rem" }}><IoIosLogOut /></p> */}
          <p 
           
            id="logoutbutton-new"
               
                onClick={handleLogout}>Logout</p>
        </div>
      </div>
      <div style={{ display: "flex", flexDirection: "row", overflowX: "hidden" }}>
        <div className="side-container-offer-main">
          {/* Image Offer Section */}
          <div className="offer">
            <button style={{color:"white"}} className="collapsible" onClick={() => toggleOffer("image")}>+ {businesstype} Image Offer</button>
            <div className={`content ${activeOffer === "image" ? "show" : ""}`}>
              <input
                type="file"
                accept="image/*"
                onChange={(e) => setImageFile(e.target.files[0])}
                className="image-uploaderss"
                id="image-offer-pic"
              />
              <div className="image-offer-validation-container">
                <div className="from-address">
                  <p className="from">Start</p>
                  <input
                    className="img-offer-inp"
                    type="date"
                    value={imageOfferStartDate}
                    onChange={(e) => setImageOfferStartDate(e.target.value)}
                    placeholder="DD-MM-YYYY"
                  />
                  <input
                    className="img-offer-inp"
                    type="time"
                    value={imageOfferStartTime}
                    onChange={(e) => setImageOfferStartTime(e.target.value)}
                    placeholder="HH:MM:SS"
                  />
                </div>
                <div className="from-address">
                  <p className="to">Ends</p>
                  <input
                    className="img-offer-inp"
                    type="date"
                    value={imageOfferEndDate}
                    onChange={(e) => setImageOfferEndDate(e.target.value)}
                    placeholder="DD-MM-YYYY"
                  />
                  <input
                    className="img-offer-inp"
                    type="time"
                    value={imageOfferEndTime}
                    onChange={(e) => setImageOfferEndTime(e.target.value)}
                    placeholder="HH:MM:SS"
                  />
                </div>
                <button className="img-offer-create-btn" onClick={handleImageOfferSubmit}>Create</button>
              </div>
            </div>
          </div>

          {/* Description Offer Section */}
          <div className="offer">
            <button style={{color:"white"}} className="collapsible" onClick={() => toggleOffer("description")}>+ {businesstype} Description Offer</button>
            <div className={`content ${activeOffer === "description" ? "show" : ""}`}>
              <div className="description-offer-container">
                <input
                  type="file"
                  accept="image/*"
                  onChange={(e) => setDescriptionImageFile(e.target.files[0])}
                  className="image-uploaderss"
                  id="desc-offer-back"
                />
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <strong>Description Title</strong>
                  <input
                    className="img-offer-inp"
                    type="text"
                    value={descriptionOfferTitle}
                    onChange={(e) => setDescriptionOfferTitle(e.target.value)}
                    placeholder="Description Title here..."
                  />
                </div>
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <strong>Description</strong>
                  <input type="text"
                    value={offerDescription}
                    onChange={(e) => setOfferDescription(e.target.value)}
                  />
                </div>
                <div className="image-offer-validation-container" style={{ marginTop: "2rem" }}>
                  <div className="from-address">
                    <p style={{ fontSize: "1.3rem", fontWeight: "600" }}>Start</p>
                    <input
                      className="img-offer-inp"
                      type="date"
                      value={descriptionOfferStartDate}
                      onChange={(e) => setDescriptionOfferStartDate(e.target.value)}
                    />
                    <input
                      className="img-offer-inp"
                      type="time"
                      value={descriptionOfferStartTime}
                      onChange={(e) => setDescriptionOfferStartTime(e.target.value)}
                      placeholder="HH:MM:SS"
                    />
                  </div>
                  <div className="from-address">
                    <p style={{ fontSize: "1.3rem", fontWeight: "600" }}>Ends </p>
                    <input
                      className="img-offer-inp"
                      type="date"
                      value={descriptionOfferEndDate}
                      onChange={(e) => setDescriptionOfferEndDate(e.target.value)}
                    />
                    <input
                      className="img-offer-inp"
                      type="time"
                      value={descriptionOfferEndTime}
                      onChange={(e) => setDescriptionOfferEndTime(e.target.value)}
                      placeholder="HH:MM:SS"
                    />
                  </div>
                  <button className="img-offer-create-btn" onClick={handleDescriptionOfferSubmit}>Create Offer</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default VendorOfferSelect;
